import React from 'react';
/*import {
  CommandBar,
} from 'office-ui-fabric-react';*/
import {
  CommandBar
} from '@fluentui/react';
import { IResStr } from '../../application/resstr';
import { ISemTalkSVG, SemTalkOnlineCommand } from '../stsvg/SemTalkSvg';
export interface ISemTalkToolBarProps {
  callback: ISemTalkSVG;
  res: IResStr;
  strings: ISemTalkStrings;
  showmenubar: boolean;
}
export interface ISemTalkToolBarState {
  items: any[];
}
export class SemTalkToolBar extends React.Component<ISemTalkToolBarProps, ISemTalkToolBarState> {
  private leftitems: any[] = [];
  private forwardItem: any;
  private backwardItem: any;

  constructor(props: ISemTalkToolBarProps) {
    super(props);
    console.debug("showToolbar");

    this.state = {
      items: this.updateItems()
    };
  }
  public componentDidMount() {
    // this.items = this.updateItems();
    if (this.props.showmenubar) {
      this.faritems=[];
    }
    this.leftitems = this.getCommandBarItems(this.state.items);
  }

  public render(): React.ReactElement<ISemTalkToolBarProps> {
    return (
      <div>
        <CommandBar
           items={this.leftitems}
          farItems={this.faritems}
        />
      </div>

    );
  }

  private updateItems = (): any[] => {

    let res = this.props.callback.ParseToolBar(true, this);
    let dynitems = res["allitems"];

    if (dynitems["goForward"]) {
      this.forwardItem = dynitems["goForward"];
      this.forwardItem.disabled = true;
    }
    if (dynitems["goBack"]) {
      this.backwardItem = dynitems["goBack"];
      this.backwardItem.disabled = true;
    }
    return res["items"];
  }
  private getCommandBarItems = (items: any): any[] => {
    let leftItems: any[] = [];
    function pushToItem(item: any) {
      leftItems.push(item);
    }
    for (let item of items) {
      pushToItem(item);
    }
    return leftItems;
  }
  private faritems = [
    {
      "key": "settings1",
      "name": this.props.res.getResStr("STROPTIONS"),
      "textL": "",
      "iconOnly": true,
      "iconProps": {
        "iconName": "Settings"
      },
      onClick: () => {
        this.props.callback.DoCommand(SemTalkOnlineCommand.ShowOptions, {});
      }
    },
    {
      key: 'info',
      name: this.props.res.getResStr("STRHELP").replace("&", ""),
      cacheKey: 'myCacheKeyInfo', // changing this key will invalidate this items cache
      iconProps: {
        iconName: 'Help'
      },
      iconOnly: true,
      keyEvent: "F1",
      onClick: () => {
        this.props.callback.showHelp("SemTalk-Overview");
        // this.props.callback.DoCommand(SemTalkOnlineCommand.ShowHelp, {});
      }
    }
  ];


}
