/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';

// import { HttpClient, IHttpClientOptions, HttpClientResponse } from "@microsoft/sp-http";
// import { WebPartContext } from '@microsoft/sp-webpart-base';
import { stconfig } from "./stconfig";
import { ISemService } from "../../options";

(window as any).context = null;

// var service: string = stconfig._service;
let _filter: string = stconfig._filter;
let _token: string = stconfig._token;
let _service: string = stconfig._service;

export function SetContext(semservice: ISemService): void {
  (window as any).context = semservice.context;
  _filter = semservice.filter;
  _service = semservice.service;
  // _service = "http://localhost:53708/semservice/"; 
  // _filter = "0";
  if (_service.endsWith("/sembasedbjs")) {
    _service = _service.replace("sembasedbjs", "");
  }
  switch (semservice.servertype) {
    case "mssql": {
      _service += "sembasedbjs";
      break;
    }
    case "mysql": {
      _service += "sembasemysqldbjs";
      break;
    }
    case "mongo": {
      _service += "sembasemongojs";
      break;
    }
  }
  if (semservice.token !== null) {
    _token = encodeURIComponent(semservice.token);
  }
}

export function SetConnection(service: string, token: string, site: string, servertype: string): void {
  _filter = site;
  _service = service;
  switch (servertype) {
    case "mssql": {
      _service += "/sembasedbjs";
      break;
    }
    case "mysql": {
      _service += "/sembasemysqldbjs";
      break;
    }
    case "mongo": {
      _service += "/sembasemongojs";
      break;
    }
  }
  _token = encodeURIComponent(token);
}
export function resetcaches() {
  tableUrlcache = {};
  stableIDUrlcache = {};
  tableTypeNamecache = {};
  tableIDUrlcache = {};
  tableNameIDcache = {};
  tableIDcache = {};
}

export async function GetTableAsync1(sn: string, str: string): Promise<any> {
  const url = _service + "/" + sn + encodeURI(str);
  let headers = {
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
    };
  let res = await axios.get(url, {"headers": headers});
  if (res.status === 200 && res.data) {
    return res.data;
  } else {
    console.log("GetTableAsync1: " + res.statusText);
    return null;
  }
}

export async function GetTable(sn: string): Promise<any[]> {
  let str = "?constr=" + _token;
  return GetTableAsync1(sn, str);
}
export async function GetTableL(sn: string): Promise<any[]> {
  let str = "?constr=" + _token + "&l=1";
  return GetTableAsync1(sn, str);
}


let tableUrlcache: { [id: string]: any } = {};
export async function GetTableUrl(sn: string): Promise<any> {
  const s0 = sn + "_" + _filter;
  let c = tableUrlcache[s0];
  if (c) {
    return c;
  }
  let str = "?siteid=" + _filter + "&constr=" + _token;
  let res = GetTableAsync1(sn, str);
  tableUrlcache[s0] = res;
  return res;
}
// export async function GetsTableUrl(s: string, sn: string) {
//   let str = "?siteid=" + _filter + "&s=" + s + "&constr=" + _token;
//   return GetTableAsync1(sn, str);
// }

let stableIDUrlcache: { [id: string]: any } = {};
export async function GetsTableIDUrl(id: number, s: string, sn: string) {
  const s0 = sn + "_" + _filter + "_" + id + "_" + encodeURIComponent(s);
  let c = stableIDUrlcache[s0];
  if (c) {
    return c;
  }
  let str = "?siteid=" + _filter + "&id=" + id + "&s=" + s + "&constr=" + _token;
  let res = GetTableAsync1(sn, str);
  stableIDUrlcache[s0] = res;
  return res;

}
const stableOPUrlcache: { [id: string]: any } = {};
export async function GetsTableAsyncOPUrl(s: string, o: number, p: number, sn: string) {
  const s0 = sn + "_" + _filter + "_" + o + "_" + p + "_" + encodeURIComponent(s);
  let c = stableOPUrlcache[s0];
  if (c) {
    return c;
  }
  let str = "?siteid=" + _filter + "&s=" + s + "&o=" + o + "&p=" + p + "&constr=" + _token;
  let res = GetTableAsync1(sn, str);
  stableOPUrlcache[s0] = res;
  return res;
}

let tableTypeNamecache: { [id: string]: any } = {};
export async function GetTableByTypeNameUrl(otype: string, name: string, sn: string) {
  const s0 = sn + "_" + _filter + "_" + otype + "_" + encodeURIComponent(name);
  let c = tableTypeNamecache[s0];
  if (c) {
    return c;
  }
  let str = "?siteid=" + _filter + "&type=" + otype + "&name=" + name + "&constr=" + _token;
  let res = GetTableAsync1(sn, str);
  tableTypeNamecache[s0] = res;
  return res;
}

let tableIDUrlcache: { [id: string]: any } = {};
export async function GetTableByIDUrl(id: number, sn: string) {
  const s0 = sn + "_" + _filter + "_" + id;
  let c = tableIDUrlcache[s0];
  if (c) {
    return c;
  }
  let str = "?siteid=" + _filter + "&id=" + id + "&constr=" + _token;
  const res = GetTableAsync1(sn, str);
  tableIDUrlcache[s0] = res;
  return res;
}
// export async function GetTableByIDLangUrl(id: number, langid: string, sn: string) {
//   let str = "?siteid=" + _filter + "&id=" + id + "&langid=" + langid + "&constr=" + _token;
//   return GetTableAsync1(sn, str);
// }

let tableNamecache: { [id: string]: any } = {};
export async function GetTableByNameUrl(name: string, sn: string) {
  const s0 = sn + "_" + _filter + "_" + encodeURIComponent(name);
  let c = tableNamecache[s0];
  if (c) {
    return c;
  }
  let str = "?siteid=" + _filter + "&name=" + name + "&constr=" + _token;
  let res = GetTableAsync1(sn, str);
  tableNamecache[s0] = res;
  return res;
}

let tableNameIDcache: { [id: string]: any } = {};
export async function GetTableByNameIDUrl(name: string, id: number, sn: string) {
  const s0 = sn + "_" + _filter + "_" + id + "_" + encodeURIComponent(name);
  let c = tableNameIDcache[s0];
  if (c) {
    return c;
  }
  let str = "?siteid=" + _filter + "&name=" + encodeURIComponent(name) + "&id=" + id + "&constr=" + _token;
  let res = GetTableAsync1(sn, str);
  tableNameIDcache[s0] = res;
  return res;
}

export async function GetTableIDUrlmima(id: number, mi: number, ma: number, sn: string) {
  let str = "?siteid=" + _filter + "&id=" + id + "&mi=" + mi + "&ma=" + ma + "&constr=" + _token;
  return GetTableAsync1(sn, str);
}
export async function GetTableAsyncIDUrlmimafi(id: number, mi: number, ma: number, fi: string, sn: string) {
  let str = "?siteid=" + _filter + "&id=" + id + "&mi=" + mi + "&ma=" + ma + "&fi=" + fi + "&constr=" + _token;
  return GetTableAsync1(sn, str);
}
export async function GetTableAsyncIDUrlcolid(id: number, colid: number, sn: string) {
  let str = "?siteid=" + _filter + "&id=" + id + "&colid=" + colid + "&constr=" + _token;
  return GetTableAsync1(sn, str);
}
export async function GetTableAsyncIDUrlrowid(id: number, rowid: number, sn: string) {
  let str = "?siteid=" + _filter + "&id=" + id + "&rowid=" + rowid + "&constr=" + _token;
  return GetTableAsync1(sn, str);
}
export async function GetTableAsyncIDRelIDUrl(id: number, relid: number, sn: string) {
  let str = "?siteid=" + _filter + "&id=" + id + "&relid=" + relid + "&constr=" + _token;
  return GetTableAsync1(sn, str);
}

let tableIDcache: { [id: string]: any } = {};
export async function GetTableByID(id: number, sn: string) {
  const s0 = sn + "_" + id;
  let c = tableIDcache[s0];
  if (c) {
    return c;
  }
  let str = "?id=" + id + "&constr=" + _token;
  const res = GetTableAsync1(sn, str);
  tableIDcache[s0] = res;
  return res;
}
