import * as React from 'react';
import { BreadCrumbs, FindDiagramByID, IDiagramTable } from '../../application/dbase';
//import { Breadcrumb, IBreadcrumbItem } from 'office-ui-fabric-react/lib/Breadcrumb';
// import { WebPartContext } from '@microsoft/sp-webpart-base';
import {
  Breadcrumb, IBreadcrumbItem 
} from '@fluentui/react';
// import { SetContext } from '../../application/restinterface';
import { gotoDocument, addCallBack, removeCallBack } from '../../application/stglobal';
import { getDiagram } from '../../application/stgoto';
import { Guid } from "guid-typescript";
import { ISemService } from '../../../options';

export interface ISemTalkBreadCrumbsProps {
  semservice: ISemService;
  // context?: any;
  // filter: string;
  // token: string;
  // service: string;
}
export interface ISemTalBreadCrumbsState {
  objectid: number;
  object: IDiagramTable | null;
  // proptable: IRefinementTable[];
  items: IBreadcrumbItem[];
}
export class SemTalkBreadCrumbs extends React.Component<ISemTalkBreadCrumbsProps, ISemTalBreadCrumbsState> {
  // private _selection: Selection;
  //  private _list: List;
  public callback: Guid;

  constructor(props: ISemTalkBreadCrumbsProps) {
    super(props);
    console.log("showBreadCrumbs");
    this.callback = Guid.create();
    this.state = {
      objectid: -1,
      object: null,
      items: []
    };
  }

  private mounted: boolean = false;
  public async componentDidMount() {
    // if (this.props.semservice.context) {
    //   SetContext(this.props.semservice);
    // }
    addCallBack(this);
    this.mounted = true;
    let pg: number = getDiagram();
    if (pg > 0) {
      await this.loadDiag(pg);
    }
  }
  public componentWillUnmount() {
    removeCallBack(this);
    this.mounted = false;
  }
  private async loadDiag(id: number) {
    let di = await FindDiagramByID(id);
    let bc = await BreadCrumbs(id);
    let items: any[] = [];
    for (let p of bc) {
      items.push({ text: p.ObjectCaption, key: p.ID, onClick: this._onBreadcrumbItemClicked });
    }
    items = items.reverse();
    if (di && di !== null) {
      let did: number = di.ID;
      if (items.findIndex(x => x.key === did) < 0) {
        items.push({ text: di.ObjectCaption, key: di.ID, onClick: this._onBreadcrumbItemClicked });
      }
    }
    this.setState({
      items: items,
      object: di,
      objectid: id
    });
  }
  public handleEvent = async (m: any): Promise<void> => {
    let mstr = JSON.stringify(m);
    await this.eventListener({ data: mstr });
  }

  private eventListener = async (e: any) => {
    if (!this.mounted) {
      return;
    }
    let md: any;
    let mtype: string = "";
    try {
      md = JSON.parse(e.data);
      mtype = md.type;
    } catch (e) {
      return;
    }
    switch (mtype) {
      case "gotoDocument":
        if (md.diagid && this.state.objectid !== md.diagid) {
          await this.loadDiag(md.diagid);
        }
        break;
      case "gotoObject":
        break;
      case "gotoNode":
        if (md.diagid && this.state.objectid !== md.diagid) {
          await this.loadDiag(md.diagid);
        }
        break;
    }
  }

  public render(): React.ReactElement<ISemTalkBreadCrumbsProps> {
    // this._selection = new Selection({
    // });

    return (
      <div>
        <Breadcrumb
          items={this.state.items}
        />
      </div>
    );
  }
  private _onBreadcrumbItemClicked = async (_ev: React.MouseEvent<HTMLElement>, item: IBreadcrumbItem) => {
    console.log(`Breadcrumb item with key "${item.key}" has been clicked.`);
    await gotoDocument(parseInt(item.key));
  }
}