import React, {ReactNode } from "react";
/*import {
     Dialog, IDialogProps, DialogFooter,IDialogFooterProps
  } from 'office-ui-fabric-react';*/
  import {
    Dialog, IDialogProps, DialogFooter,IDialogFooterProps
  } from '@fluentui/react';
  
type IDialogPropsEx = IDialogProps & { children: ReactNode; }; 
export const DialogEx: React.FunctionComponent<IDialogPropsEx> = ({ children, ...props }) => { 
    return <Dialog {...props}>{children}</Dialog>; 
}; 
type IDialogFooterPropsEx = IDialogFooterProps & { children: ReactNode; }; 
export const DialogFooterEx: React.FunctionComponent<IDialogFooterPropsEx> = ({ children, ...props }) => { 
    return <DialogFooter {...props}>{children}</DialogFooter>; 
};