import { SemTalkCookie } from "../ISemTalkCookie";
import { SemTalkPortalCookie } from "../ISemTalkPortalCookie";
import {
  FindModelByName, FindDiagramByNameID, FindNodeByShape,
  // FindObject, FindInstanceByID, 
  FindDiagramByID,
  FindModelByID, FindObjectByID,
  FindObject,
  // FindObjectByName, 
  FindNodeByID,
  Links, Log, IModelTable,
  // LinkedObjects
} from "./dbase";

import { stconfig } from "./stconfig";
import * as stgoto from "./stgoto";

let bArgsParsed: boolean = false;

export async function getHostPageInfoListener(md1: string) {
  let md: any;
  try {
    //  console.log(e.data);
    md = JSON.parse(md1);
    let mtype = md.type;
    // let shapeID = md.shapeID;
    // let pagename = md.pagename;
    // let modelname = md.modelname;
    switch (mtype) {
      case "shapeSelectionRemoved":
      {
        let pagename = md.pagename;
        let model = await FindModelByName(md.modelname);
        if (model === null) return;
        let modelid = model.ID;
        let diag = await FindDiagramByNameID(pagename, modelid);
        if (diag !== null) {
          await noObject(diag.ID);
          
        };
      }
      break;
      case "shapeSelectionChanged":
        {
          let pagename = md.pagename;
          let shapeid = md.shapeid;
          // console.log("SelectionChanged:" + md);
          let model = await FindModelByName(md.modelname);
          if (model === null) return;
          let modelid = model.ID;
          let diag = await FindDiagramByNameID(pagename, modelid);
          if (diag === null) return;
          let node = null;
          if(shapeid !== "") node = await FindNodeByShape(diag.ID, shapeid);
          // if (node === null) return;
          setModel(modelid);
          if (stgoto.getDiagram() !== diag.ID) {
            await gotoDocument(diag.ID);
          }
          if (node !== null) {
            // if (node.ObjectCaption === "Swimlane") {
            //   let dis = await FindObject("AssociationType", "displays")
            //   let ins = await FindInstanceByID(node.Object);
            //   if (ins && dis) {
            //     for (const othid of await LinkedObjects(ins.ID, dis.ID)) {
            //       gotoObject(othid);
            //       stgoto.setShape(shapeid);
            //       return;
            //     }
            //   }
            // } else {
            let objectid: number = node.Object;
            stgoto.setShape(shapeid);
            if (md.refine && !md.prop) {
              let plist = await Links(objectid);
              for (let prp of plist) {
                let nam = prp.PropName;
                if (nam === "Refinement" || nam === "Verfeinerung") {
                  let val: string = prp.PropValueName;
                  // global.gotoDocument(val);
                  // console.debug("SVGgotoObject", val);
                  await gotoDocument(parseInt(val));
                  return;
                }
              }
            }
            await gotoObject(objectid);
            // }
          }
        }
        break;
      case "GotoPage":
        {
          let pagename = md.pagename;
          let model = await FindModelByName(md.modelname);
          if (!model) return;
          let diag = await FindDiagramByNameID(pagename, model.ID);
          if (!diag) return;
          setModel(model.ID);
          if (stgoto.getDiagram() !== diag.ID) {
            await gotoDocument(diag.ID);
          }
        }
        break;
    }
  } catch (error) {
    // logError(error);
  }
}

export async function gotoDocument(d: number) {
  if (d !== stgoto.getDiagram()) {
    gotoDocumentInternal(d);
    let diag = await FindDiagramByID(d);
    if (!diag) return;
    let mod = await FindModelByID(diag.ObjectModel);
    if (!mod) return;
    let m: any = {};
    m.type = "gotoDocument";
    m.pagename = diag.ObjectCaption;
    if (mod.ModelName) m.modelname = mod.ModelName;
    if (mod.ObjectName) m.modelname = mod.ObjectName;
    m.modelid = mod.ID;
    m.diagid = diag.ID;
    stgoto.post2Callbacks(m);
    let mstr = JSON.stringify(m);
    await Log(mstr);
    setModel(mod.ID);
    setDiagram(diag.ID);
    window.parent.postMessage(mstr, '*');
  }
}

export async function noObject(d: number) {
  
    gotoDocumentInternal(d);
    let diag = await FindDiagramByID(d);
    if (!diag) return;
    let mod = await FindModelByID(diag.ObjectModel);
    if (!mod) return;
    let m: any = {};
    m.type = "noObject";
    m.pagename = diag.ObjectCaption;
    if (mod.ModelName) m.modelname = mod.ModelName;
    if (mod.ObjectName) m.modelname = mod.ObjectName;
    m.modelid = mod.ID;
    m.diagid = diag.ID;
    stgoto.post2Callbacks(m);
    let mstr = JSON.stringify(m);
    await Log(mstr);
    setModel(mod.ID);
    setDiagram(diag.ID);
    window.parent.postMessage(mstr, '*');
  
}

function gotoDocumentInternal(d: number) {
  if (stgoto.getDiagram() !== d) {
    stgoto.setObject(-1);
    stgoto.setDiagram(d);
  }
}
export function addCallBack(crtl: React.Component): string {
  return stgoto.addCallBack(crtl);
}
export function removeCallBack(crtl: React.Component): void {
  return stgoto.removeCallBack(crtl);
}

export function getObject(): number {
  return stgoto.getObject();
}
export function getDiagram(): number {
  return stgoto.getDiagram();
}
export function setDiagram(obj: number): void {
  stgoto.setDiagram(obj);
}
export function getModel(): number {
  return stgoto.getModel();
}
export function setModel(obj: number): void {
  stgoto.setModel(obj);
}
export async function gotoEntity(entity: any) {
  stgoto.setEntity(entity);
  let m: any = {};
  m.entity = entity;
  m.type = "gotoEntity";
  let mstr = JSON.stringify(m);
  stgoto.post2Callbacks(m);
  await Log(mstr);
}
export function getEntity(): string {
  return stgoto.getEntity();
}

export function setStay(_stay: boolean): void {
  stgoto.setStay(_stay);
}
// export function setSite(site: string): void {
//   stgoto.setSite(site);
// }
export function accessCookie(cookieName: SemTalkCookie | SemTalkPortalCookie): string {
  let c = stgoto.accessCookie(cookieName);
  if (!c) c = "";
  return c;
}
export function setCookie(cookieName: SemTalkCookie | SemTalkPortalCookie, cookieValue: any) {
  return stgoto.setCookie(cookieName, cookieValue);
}
export function removeCookie(cookieName: SemTalkCookie | SemTalkPortalCookie): string {
  let c = stgoto.removeCookie(cookieName);
  if (!c) c = "";
  return c;
}
export function getShape(): string {
  return stgoto.getShape();
}
export function getContext(): string {
  return stgoto.getContext();
}

export async function gotoObject(o: number) {
  // console.log("gotoObject: ", o);
  if (stgoto.getObject() !== o) {
    stgoto.setObject(o);
    if (o > -1) {
      let obj = await FindObjectByID(o);
      if (obj !== null) {
        //stgoto.setModel(obj.ObjectModel);
        let mod = await FindModelByID(obj.ObjectModel);
        if (mod !== null) {
          let m: any = {};
          m.type = "gotoObject";
          if (mod.ModelName) m.modelname = mod.ModelName;
          if (mod.ObjectName) m.modelname = mod.ObjectName;
          m.objectname = obj.ObjectName;
          m.objectcaption = obj.ObjectCaption;
          m.modelid = mod.ID;
          m.objectid = obj.ID;
          let mstr = JSON.stringify(m);
          stgoto.post2Callbacks(m);
          await Log(mstr);
        }
      }
    }
  }
}

export async function gotoObjectByName(name: string) {
  let o = await FindObject("Instance", name);
  if (o) {
    await gotoObject(o.ID);
  } else {
    let obj = await FindObject("Class", name);
    if (obj) {
      await gotoObject(obj.ID);
    }
  }

}
export function loadsettings(settings: any) {
  if (settings._filter.length > 0) {
    stconfig._filter = settings._filter;
  }
  if (settings._service.length > 0) {
    stconfig._service = settings._service;
  }
}
export async function gotoNodeByID(nd: number) {
  let n = await FindNodeByID(nd);
  if (n) await gotoNode(n.ID, n.Object, n.NodePage, n.NodeShape);
}

export async function gotoNode(_nd: number, val: number, pg: number, sh: string) {
  if (stgoto.getDiagram() !== pg) {
    gotoDocumentInternal(pg);
  }
  stgoto.setObject(val);
  // eslint-disable-next-line no-constant-condition
  if (true) {
    let node = await FindNodeByShape(pg, sh);
    if (!node) return;
    let obj = await FindObjectByID(node.Object);
    if (!obj) return;
    let diag = await FindDiagramByID(pg);
    if (!diag) return;
    let mod = await FindModelByID(node.NodeModel);
    if (!mod) return;
    stgoto.setShape(sh);
    let m: any = {};
    m.type = "gotoNode";
    m.pagename = diag.ObjectName;
    if (mod.ModelName) m.modelname = mod.ModelName;
    if (mod.ObjectName) m.modelname = mod.ObjectName;
    m.objectname = obj.ObjectName;
    m.objectcaption = node.ObjectCaption;
    m.modelid = mod.ID;
    m.objectid = obj.ID;
    m.nodeid = node.ID;
    m.diagid = diag.ID;
    m.shapeid = sh;
    stgoto.post2Callbacks(m);
    let mstr = JSON.stringify(m);
    await Log(mstr);
    window.parent.postMessage(mstr, "*");
  }
}

export function showPivot(vis: boolean) {
  let m: any = {};
  m.type = "showPivot";
  m.value = vis;
  stgoto.post2Callbacks(m);
  let mstr = JSON.stringify(m);
  window.parent.postMessage(mstr, "*");
}
export function showContext(vis: string) {
  let m: any = {};
  m.type = "showContext";
  m.value = vis;
  stgoto.post2Callbacks(m);
  let mstr = JSON.stringify(m);
  window.parent.postMessage(mstr, "*");
}
export function useDialogs(vis: string) {
  let m: any = {};
  m.type = "useDialogs";
  m.value = vis;
  stgoto.post2Callbacks(m);
  let mstr = JSON.stringify(m);
  window.parent.postMessage(mstr, "*");
}

// export function gotoFindANode(pg: number, val: number) {
// }

// export function showNode(sh: string) {
// }

export function gotoReport(_rep: string) {
  let x = document.getElementById('results');
  if (x !== null) {
    alert('results');
    // AddReport(_rep, 'results');
  }
  x = document.getElementById('results1');
  if (x !== null) {
    alert('results1');
    // AddReport(_rep, 'results1');
  }
}

// export var lastShownData = ["", "", ""];
//window.onload = global.EnableMinimizeWebpart;

export function contains(l: any[], x: any) {
  for (let i = 0, j = l.length; i < j; i++) {
    if (l[i] === x) { return true; }
  }
  return false;
}
function getQueryStringParameter(p: string) {
  try {
    let params = document.URL.split("?")[1].split("&");
    for (let i = 0; i < params.length; i = i + 1) {
      let singleParam = params[i].split("=");
      if (singleParam[0] === p)
        return decodeURIComponent(singleParam[1]);
    }
    // eslint-disable-next-line no-empty
  } catch (e) { }
  return "";
}
export async function DoArgs() {
  if (!bArgsParsed) {
    // bArgsParsed = true;
    let shapeid: string = getQueryStringParameter('shape');
    let modelname = getQueryStringParameter('model');
    let pagename = getQueryStringParameter('page');
    let objectname = getQueryStringParameter('object');

    let objectid = getQueryStringParameter('objectid');
    let pageid = getQueryStringParameter('pageid');
    let modelid = getQueryStringParameter('modelid');
    let nodeid = getQueryStringParameter('nodeid');
    if (shapeid.length === 0) {
      shapeid = getQueryStringParameter('shapeid');
    }

    let mod: IModelTable | null;
    if (modelid.length > 0 && modelname.length === 0) {
      mod = await FindModelByID(parseInt(modelid));
      if (mod) {
        if (mod.ObjectName) modelname = mod.ObjectName;
        if (mod.ModelName) modelname = mod.ModelName;
      }
    }
    if (pageid.length > 0 && pagename.length === 0) {
      let pg = await FindDiagramByID(parseInt(pageid));
      if (pg) {
        if (pg.ObjectName) pagename = pg.ObjectName;
      }
    }

    if (nodeid.length > 0 && shapeid.length > 0 && pageid.length > 0 && objectid.length > 0) {
      await gotoNode(parseInt(nodeid), parseInt(objectid), parseInt(pageid), shapeid);
      return;
    }
    if (shapeid.length > 0 && pageid.length > 0 && objectid.length > 0) {
      let md: any = {};
      md.type = "gotoNode";
      md.modelid = parseInt(modelid);
      md.modelname = modelname;
      md.pagename = pagename;
      md.diagid = parseInt(pageid);
      md.shapeid = shapeid;
      stgoto.post2Callbacks(md);
      // await gotoNode(parseInt(nodeid), parseInt(objectid), parseInt(pageid), shapeid);
      return;
    }
    if (objectid.length > 0) {
      await gotoObject(parseInt(objectid));
      return;
    }
    if (pageid.length > 0) {
      // await gotoDocument(parseInt(pageid));
      let md: any = {};
      md.type = "gotoPage";
      md.modelid = parseInt(modelid);
      md.modelname = modelname;
      md.pagename = pagename;
      stgoto.post2Callbacks(md);
      return;
    }

    if (shapeid.length > 0 && pagename.length > 0) {
      mod = await FindModelByName(modelname);
      if (mod) {
        let md: any = {};
        md.type = "gotoNode";
        md.modelid = mod.ID;
        md.modelname = modelname;
        md.pagename = pagename;
        md.shapeid = shapeid;
        stgoto.post2Callbacks(md);
        console.log("DoArgs: ", md);
        window.parent.postMessage(JSON.stringify(md), "*");
      }
    } else {
      if (pagename.length > 0) {
        mod = await FindModelByName(modelname);
        if (mod) {
          let md: any = {};
          md.type = "gotoPage";
          md.modelid = mod.ID;
          md.modelname = modelname;
          md.pagename = pagename;
          stgoto.post2Callbacks(md);
          console.log("DoArgs: ", md);
          window.parent.postMessage(JSON.stringify(md), "*");
        }
      } else {
        if (objectname.length > 0) {
          let obj = await FindObject("Instance", objectname);
          if (obj) {
            await gotoObject(obj.ID);
          } else {
            obj = await FindObject("Class", objectname);
            if (obj) {
              await gotoObject(obj.ID);
            }
          }
        }
      }
    }
  }
}

export async function setRoleProcesses(proclist: any[]) {
  let m: any = {};
  m.type = "gotoRole";
  m.roleprocesses = proclist;
  let mstr = JSON.stringify(m);
  await Log(mstr);
  window.parent.postMessage(mstr, '*');
}