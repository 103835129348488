import React, { Suspense } from 'react';
// import { escape } from '@microsoft/sp-lodash-subset';
/*import {
  Pivot,
  PivotItem,
  IPivotItemProps,
  //ThemeSettingName
} from 'office-ui-fabric-react';*/
import {
  Pivot,
  PivotItem,
  IPivotItemProps
} from '@fluentui/react';
// import { WebPartContext } from '@microsoft/sp-webpart-base';
// import { SemTalkDetailsTable } from '../stdetails/SemTalkDetails';
// import SemTalkPropertyTable from '../stproperty/SemTalkProperty';
// import { SemTalkContext } from '../stcontext/SemTalkContext';
// import { SemTalkPropertyGroupedTable } from '../stproperty_grouped/SemTalkProperty_Grouped';
// import { SemTalkLinks } from '../stlinks/SemTalkLinks';
// import { SemTalkDiagramTable } from '../stdiagram/SemTalkDiagram';
// import { SemTalkDocumentTable } from '../stdocument/SemTalkDocument';
// import { SemTalkAttachmentTable } from '../stattachment/SemTalkAttachment';
// import { SemTalkList } from '../stlistitems/SemTalkList';
// import { SemTalkListDocuments } from '../stlistdocuments/SemTalkListDocuments';
// import { SemTalkSearchDriven } from '../stsearchdriven/SemTalkSearchDriven';
// import { SemTalkTeam } from '../stteams/SemTalkTeam';
// import { SemTalkTasks } from '../sttasks/SemTalkTasks';
// import { SemTalkBot } from '../stbot/SemTalkBot';
// import { SemTalkWiki } from '../stwiki/SemTalkWiki';
import { accessCookie, addCallBack, removeCallBack } from '../../application/stglobal';
// import { MSGraphClient } from '@microsoft/sp-http';

// import { SetContext } from '../../application/restinterface';
// import { getContext } from '../../semtalkportal/stglobal';
import { Guid } from "guid-typescript";
import { ISemService } from '../../../options';
import { SemTalkPortalCookie } from '../../ISemTalkPortalCookie';
import { ISemTalkSVG } from '../stsvg/SemTalkSvg';
import { IResStr } from '../../application/resstr';
// import * as strings from 'SemTalkStrings';

const SemTalkDetailsTable = React.lazy(() => import(/* webpackChunkName: "details" */
  '../stdetails/SemTalkDetails'));
const SemTalkLinks = React.lazy(() => import(/* webpackChunkName: "links" */
  '../stlinks/SemTalkLinks'));
const SemTalkDiagramTable = React.lazy(() => import(/* webpackChunkName: "diagram" */
  '../stdiagram/SemTalkDiagram'));
const SemTalkPropertyTable = React.lazy(() => import(/* webpackChunkName: "property" */
  '../stproperty/SemTalkProperty'));
const SemTalkAttachmentTable = React.lazy(() => import(/* webpackChunkName: "attachment" */
  '../stattachment/SemTalkAttachment'));
// const SemTalkNavigator = React.lazy(() => import(/* webpackChunkName: "navigator" */
//   '../stnavigator/SemTalkNavigator'));

export interface ISemTalkPivotProps {
  semservice: ISemService;
  callback: ISemTalkSVG;
  res: IResStr;
  // title?: string;
  // description?: string;
  // width?: number;
  // height?: number;
  // minimize?: boolean;
  // help?: string;
  // context: any;
  // service: string;
  // filter: string;
  // token: string;
  // showDetails?: boolean;
  // showProps?: boolean;
  // showtype?: boolean;
  // shownodes?: boolean;
  // dividetable: boolean;
  // showproperties?: boolean;
  // shownav?: boolean;
  hidebpmn?: string[];
  hidesimulation?: string[];
  // showContext?: boolean;
  // showPropsGrouped?: boolean;
  // showLinks?: boolean;
  // showDiagram?: boolean;
  // showDocument?: boolean;
  // showAttachment?: boolean;
  // showListItems: boolean;
  // showListDocuments: boolean;
  // listitemssite?: string;
  // listitemslist?: string;
  // listitemsquery?: string;
  // listitemscolumns?: string[];
  // listdocumentslibrary?: string;
  // listdocumentssite?: string;
  // listdocumentscolumns?: string[];
  // listdocumentsquery?: string;
  // editDocumentList?: boolean;
  // editList?: boolean;
  // listFilter: {};
  // addnew?: boolean;
  gotonodes: boolean;
  views?: string;
  // showTeams?: boolean;
  // showTasks: boolean;
  // showSearchDriven?: boolean;
  // searchquery: string;
  // searchmytasks: boolean;
  // graphClient?: any;
  // usegraph?: boolean;
  // goto_context?: any;
  // portal?: string;
  // teamid?: string;
  // planid?: string;
  // channelid?: string;
  // showBot?: boolean;
  // botsecret: string;
  // showWiki?: boolean;
  // showNavigator: boolean;
  // wikilist?: string;
  // wikisite?: string;
  // wikieditlist: boolean;
  // wikiiscombo: boolean;
  // badlist: string[];
  // goodlist: string[];
  navigateproperties: boolean;
  tit_property?: string;
  tit_search?: string;
  tit_diagram?: string;
  // tit_propWithGroup?: string;
  // tit_context?: string;
  // tit_details?: string;
  tit_visiodata?: string;
  tit_share?: string;
  tit_hyperlinks?: string;
  strings: ISemTalkStrings;
}
export interface ISemTalkPivotState {
  redraw: boolean;
  goto_context?: any;
}
export class SemTalkPivot extends React.Component<ISemTalkPivotProps, ISemTalkPivotState> {
  public callback: Guid;
  constructor(props: ISemTalkPivotProps) {
    super(props);
    console.log("showPivot");
    this.callback = Guid.create();
    this.state = {
      redraw: false
    };
  }
  private mounted: boolean = false;
  public componentDidMount() {
    // if (this.props.semservice.context) {
    //   SetContext(this.props.semservice);
    // }
    addCallBack(this);
    this.mounted = true;
  }
  public componentWillUnmount() {
    removeCallBack(this);
    this.mounted = false;
  }

  public handleEvent = async (m: any): Promise<void> => {
    let mstr = JSON.stringify(m);
    this.eventListener({ data: mstr });
  }

  private eventListener = (e: any) => {
    if (!this.mounted) {
      return;
    }
    let md: any;
    let mtype: string = "";
    try {
      md = JSON.parse(e.data);
      mtype = md.type;
    } catch (e) {
      return;
    }
    switch (mtype) {
      case "gotoDocument":
        break;
      case "gotoObject":
        break;
      case "gotoNode":
        //  this.setState({goto_context: getContext()});
        break;
    }
  }
  public render(): React.ReactElement<ISemTalkPivotProps> {
    let pivotArray: React.ReactElement<IPivotItemProps>[] = [];
    // console.debug("RenderPivot: ", this.props.context);
    let propDetails: string = this.props.strings.DetailsLabel;
    // if (this.props.tit_details !== "") {
    //   propDetails = this.props.tit_details;
    // }
    let suspfallback = <div />;

    let sde = accessCookie(SemTalkPortalCookie.pivot_showdetails);
    let showdetails: boolean = false;
    if (sde === 'true') {
      showdetails = true;
    }

    if (showdetails) {
      pivotArray.push(
        <PivotItem headerText={propDetails} key="Details">
          <div>
            <Suspense fallback={suspfallback}>
              <SemTalkDetailsTable
                semservice={this.props.semservice}
                gotonodes={this.props.gotonodes}
                strings={this.props.strings}
              />
            </Suspense>
          </div>
        </PivotItem>
      );
    }

    let propTitle= this.props.tit_property;
    // if (this.props.tit_property !== "") {
    //   propTitle = this.props.tit_property;
    // }
    let pnd = accessCookie(SemTalkPortalCookie.pivot_showprops);
    let showprops: boolean = true;
    if (pnd === 'false') {
      showprops = false;
    }
    if (showprops) {
      pivotArray.push(
        <PivotItem headerText={propTitle} key="Props">
          <div>
            <Suspense fallback={suspfallback}>
              <SemTalkPropertyTable
                semservice={this.props.semservice}
                res={this.props.res}
                // goodlist={this.props.goodlist}
                // badlist={this.props.badlist}
                // graphClient={this.props.graphClient}
                // navigateproperties={true}
                hidebpmn={this.props.hidebpmn}
              // strings={this.props.strings}
              />
            </Suspense>
          </div>
        </PivotItem>
      );
    }
    // let propContext: string = this.props.strings.ContextLabel;

    // if (this.props.showContext) {
    //   pivotArray.push(
    //     <PivotItem linkText={propContext} key="Context">
    //       <div>
    //         {/* <SemTalkContext context={this.props.context}
    //           site={this.props.listitemssite} listTitle={"Roles"}
    //           goodlist={this.props.goodlist}
    //           badlist={this.props.badlist}
    //           gotonodes={this.props.gotonodes}
    //           width={300} height={500}
    //           root="piroot"
    //           controls={true}
    //           navigateproperties={this.props.navigateproperties}
    //           graphClient={this.props.graphClient}
    //           usegraph={this.props.usegraph}
    //           filter={this.props.filter}
    //           token={this.props.token}
    //           service={this.props.service}>
    //         </SemTalkContext> */}
    //       </div>
    //     </PivotItem>
    //   );
    // }
    // let propGroupProps: string = this.props.strings.PropsLabel;
    // // if (this.props.tit_propWithGroup !== "") {
    // //   propGroupProps = this.props.tit_propWithGroup;
    // // }
    // if (this.props.showPropsGrouped) {
    //   console.log(this.props.views);
    //   pivotArray.push(
    //     <PivotItem linkText={propGroupProps} key="Props">
    //       <div>
    //         {/* <SemTalkPropertyGroupedTable context={this.props.context}
    //           goodlist={this.props.goodlist} badlist={this.props.badlist}
    //           filter={this.props.filter} token={this.props.token} 
    //           service={this.props.service}
    //           hidebpmn={this.props.hidebpmn}
    //           hidesimulation={this.props.hidesimulation}
    //           gotonodes={this.props.gotonodes}
    //           views={this.props.views}
    //         >
    //         </SemTalkPropertyGroupedTable> */}
    //       </div>
    //     </PivotItem>
    //   );
    // }

    let sli = accessCookie(SemTalkPortalCookie.pivot_showlin);
    let showlin: boolean = false;
    if (sli === 'true') {
      showlin = true;
    }
    let propShare= this.props.tit_share;
    // if (this.props.tit_share !== "") {
    //   propShare = this.props.tit_share;
    // }
    if (showlin) {
      pivotArray.push(
        <PivotItem linkText={propShare} key="Links">
          <div>
            <Suspense fallback={suspfallback}>
              <SemTalkLinks
                semservice={this.props.semservice}
                gotonodes={this.props.gotonodes}
                strings={this.props.strings}
              />
            </Suspense>
          </div>
        </PivotItem>
      );
    }
    let sdi = accessCookie(SemTalkPortalCookie.pivot_showdia);
    let showdia: boolean = false;
    if (sdi === 'true') {
      showdia = true;
    }
    let propDiagram= this.props.tit_diagram;
    // if (this.props.tit_diagram !== "") {
    //   propDiagram = this.props.tit_diagram;
    // }
    if (showdia) {
      pivotArray.push(
        <PivotItem linkText={propDiagram} key="Diag">
          <div>
            <Suspense fallback={suspfallback}>
              <SemTalkDiagramTable
                semservice={this.props.semservice}
                // goodlist={["Refinement", "Verfeinerung", "Go Up", "Aufwärts"]}
                // badlist={[]}
                // navigateproperties={true}
                strings={this.props.strings}
              // gotonodes={true} 
              />
            </Suspense>
          </div>
        </PivotItem>
      );
    }
    // let propSearch: string = this.props.strings.SearchDrivenLabel;
    // if (this.props.tit_search !== "") {
    //   propSearch = this.props.tit_search;
    // }
    // if (this.props.showSearchDriven) {
    //   pivotArray.push(
    //     <PivotItem linkText={propSearch} key="Context">
    //       <div>
    //         {/* <SemTalkSearchDriven context={this.props.context}
    //           gotonodes={this.props.gotonodes} graphClient={this.props.graphClient}
    //           filter={this.props.filter} token={this.props.token} service={this.props.service} query={this.props.searchquery}
    //         >
    //         </SemTalkSearchDriven> */}
    //       </div>
    //     </PivotItem>
    //   );
    // }
    // let propVisioDoc: string = this.props.strings.DocumentLabel;
    // if (this.props.tit_visiodata !== "") {
    //   propVisioDoc = this.props.tit_visiodata;
    // }
    // if (this.props.showDocument) {
    //   pivotArray.push(
    //     <PivotItem linkText={propVisioDoc} key="Doc">
    //       <div>
    //         {/* <SemTalkDocumentTable context={this.props.context} filter={this.props.filter} token={this.props.token} service={this.props.service}
    //           goodlist={[]} badlist={[]}></SemTalkDocumentTable> */}
    //       </div>
    //     </PivotItem>
    //   );
    // }
    let sat = accessCookie(SemTalkPortalCookie.pivot_showatt);
    let showatt: boolean = false;
    if (sat === 'true') {
      showatt = true;
    }
    let propHyperlink = this.props.tit_hyperlinks; // strings.AttachmentLabel;
    // if (this.props.tit_hyperlinks !== "") {
    //   propHyperlinks = this.props.tit_hyperlinks;
    // }
    if (showatt) {
      pivotArray.push(
        <PivotItem linkText={propHyperlink} key="Att">
          <div>
            <Suspense fallback={suspfallback}>
              <SemTalkAttachmentTable
                semservice={this.props.semservice}
                strings={this.props.strings}
              />
            </Suspense>
          </div>
        </PivotItem>
      );
    }
    // if (this.props.showTeams && this.props.graphClient) {
    //   pivotArray.push(
    //     <PivotItem linkText={this.props.strings.Planner} key="Tea">
    //       <div>
    //         {/* <SemTalkTeam context={this.props.context} filter={this.props.filter} token={this.props.token} service={this.props.service}
    //           site={this.props.listitemssite}
    //           portal={this.props.portal}
    //           graphClient={this.props.graphClient}
    //           searchmytasks={this.props.searchmytasks}
    //           teamid={this.props.teamid}
    //           planid={this.props.planid}>
    //         </SemTalkTeam> */}

    //       </div>
    //     </PivotItem>
    //   );
    // }
    // if (this.props.showTasks && this.props.graphClient) {
    //   pivotArray.push(
    //     <PivotItem linkText={this.props.strings.Task} key="Tas">
    //       <div>
    //         {/* <SemTalkTasks context={this.props.context} filter={this.props.filter} token={this.props.token} service={this.props.service}
    //           graphClient={this.props.graphClient}
    //           teamid={this.props.teamid}
    //           planid={this.props.planid}
    //           gotonodes={true}>
    //         </SemTalkTasks> */}
    //       </div>
    //     </PivotItem>
    //   );
    // } 
    // if (this.props.showBot) {
    //   pivotArray.push(
    //     <PivotItem linkText={this.props.strings.Bot} key="bot">
    //       <div>
    //         <SemTalkBot context={this.props.context}
    //           filter={this.props.filter} token={this.props.token} service={this.props.service}
    //           directLineSecret={this.props.botsecret}>
    //         </SemTalkBot>
    //       </div>
    //     </PivotItem>
    //   );
    // }
    // if (this.props.showListItems) {
    //   pivotArray.push(
    //     <PivotItem linkText={this.props.listitemslist} key="list">
    //       <div>
    //         {/* <SemTalkList
    //           context={this.props.context} filter={this.props.filter} token={this.props.token} service={this.props.service}
    //           site={this.props.listitemssite} listTitle={this.props.listitemslist}
    //           query={this.props.listitemsquery} columns={this.props.listitemscolumns}
    //           addnew={this.props.addnew}
    //           editList={this.props.editList}
    //           listFilter={this.props.listFilter}
    //           gotoPage={true}
    //           gotoObject={true}
    //           gotonodes={this.props.gotonodes}
    //         >
    //         </SemTalkList> */}
    //       </div >
    //     </PivotItem >
    //   );
    // }
    // if (this.props.showListDocuments) {
    //   pivotArray.push(
    //     <PivotItem linkText={this.props.listdocumentslibrary} key="listdoc">
    //       <div>
    //         {/* <SemTalkListDocuments
    //           context={this.props.context} filter={this.props.filter} token={this.props.token} service={this.props.service}
    //           site={this.props.listdocumentssite} listTitle={this.props.listdocumentslibrary}
    //           query={this.props.listdocumentsquery} columns={this.props.listdocumentscolumns}
    //           editDocumentList={this.props.editDocumentList}
    //           graphClient={this.props.graphClient}
    //           listFilter={this.props.listFilter}
    //           gotoPage={true}
    //           gotoObject={true}
    //           gotonodes={this.props.gotonodes}
    //         >
    //         </SemTalkListDocuments> */}
    //       </div >
    //     </PivotItem >
    //   );
    // }
    // if (this.props.showWiki) {
    //   pivotArray.push(
    //     <PivotItem linkText={this.props.strings.WikiLabel} key="wiki">
    //       <div>
    //         <SemTalkWiki
    //           context={this.props.context} goodlist={this.props.goodlist} badlist={this.props.badlist}
    //           filter={this.props.filter} token={this.props.token} service={this.props.service}
    //           wikisite={this.props.wikisite}
    //           wikilist={this.props.wikilist}
    //           addnew={this.props.addnew}
    //           wikieditlist={this.props.wikieditlist}
    //           gotoPage={true}
    //           gotoObject={true}
    //           gotoNodes={false}
    //           // gotoShapes={false}
    //           iscombo={this.props.wikiiscombo}
    //           navigateproperties={true}
    //           showtype={this.props.showtype}
    //           shownodes={this.props.shownodes}
    //           showproperties={this.props.showproperties}
    //           shownav={this.props.shownav}
    //           hidebpmn={this.props.hidebpmn}
    //           hidesimulation={this.props.hidesimulation}
    //           dividetable={this.props.dividetable}
    //         >
    //         </SemTalkWiki>
    //       </div >
    //     </PivotItem >
    //   );
    // }
    return (
      // <Fabric>
      <div>
        {pivotArray.length > 0 &&
          <Pivot>
            {pivotArray}
          </Pivot>
        }
      </div>
      //  </Fabric>
    );
  }
}
