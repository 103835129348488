import React, { Suspense } from 'react';
import styles from './SemTalkSvg.module.scss';
import * as Snap from 'snapsvg-cjs';
import svgPanZoom from 'svg-pan-zoom';

// import { SemTalkBreadCrumbs } from '../stbreadcrumbs/SemTalkBreadCrumbs';
// import { SemTalkCommandBar } from '../stcommandbar/SemTalkCommandBar';
import { SemTalkPivot } from '../stpivot/SemTalkPivot';
// import { SemTalkContext } from '../stcontext/SemTalkContext';
// import SemTalkRepository from '../strepository/SemTalkRepository';

import {
  // FindModelByID, 
  FindDiagramByNameID, ModelDiagramSVG,
  FindModelByName, FindNodeByShape, IModelAttributeTable, SetApi,
  //FindObjectByID, 
  Links, InvLinks,
  NodeNames,
  AllSites,
  /* IPropertyTable*/
  // FindModelByID,
  // FindDiagramByID,
  // IDiagramTable,
  
} from '../../application/dbase';
import { SetContext } from '../../application/restinterface';
import { getDiagram, getHostPageInfoListener, getModel, setCookie } from '../../application/stglobal';
import { DoArgs, setModel, setDiagram } from '../../application/stglobal';
import {
  addDocumentToHistory, IHistory, IHistoryEntry,
  isBackward, isForward, resetHistory
} from '../../SemTalkHistory';
import {
  addCallBack, removeCallBack, setStay,
  gotoNode, gotoDocument, gotoObject
} from '../../application/stglobal';
// import { MSGraphClient } from '@microsoft/sp-http';
/*import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react';*/
import {
  initializeIcons, Panel, PanelType,DialogType, ResponsiveMode, DefaultButton, PrimaryButton, ContextualMenu, ContextualMenuItemType, Fabric, IContextualMenuItem, Spinner, SpinnerSize, Stack, StackItem 
} from '@fluentui/react';
// import { DisplayMode } from '@microsoft/sp-core-library';
// import { WebPartContext } from '@microsoft/sp-webpart-base';
import { Guid } from "guid-typescript";
/*import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';

import { DialogType, ResponsiveMode } from 'office-ui-fabric-react/lib/Dialog';
*/
import { DialogEx, DialogFooterEx } from "../../dialogex";
// import * as base64 from 'base-64';
import * as utf8 from 'utf8';
import lzutf8 from 'lzutf8';

import { SemTalkCommandBar } from '../stcommandbar/SemTalkCommandBar';
import { SemTalkToolBar } from '../sttoolbar/SemTalkToolBar';
import { strings_de } from '../../../strings/semTalkStrings/loc/de-de';
import { strings_en } from '../../../strings/semTalkStrings/loc/en-us';
import { SemTalkBreadCrumbs } from '../stbreadcrumbs/SemTalkBreadCrumbs';
import { ResStr } from '../../application/resstr';
import { IMongoOption } from '../../SemTalkMongoServer';
import { SemTalkLanguageCode } from '../../application/tbase/Interface';
import { ISemService } from '../../../options';

import { SemTalkCookie } from '../../ISemTalkCookie';
import SemTalkReport from '../streport/SemTalkReport';
// import SemTalkRepository from '../strepository/SemTalkRepository';
//import { ContextualMenuItemType, Fabric, IContextualMenuItem, Spinner, SpinnerSize, Stack, StackItem } from 'office-ui-fabric-react';
import { SemTalkPortalCookie } from '../../ISemTalkPortalCookie';
//import { ContextualMenu } from 'office-ui-fabric-react';
import * as ribbontemplate from '../../application/ribbon.json';
import { GetLanguageCode } from '../../application/tbase/langtools';
import { ISemTalkAzureOptions, ISemTalkTeamsOptions } from '../../SemTalkOptions';

import SemTalkPropertyTable from '../stproperty/SemTalkProperty';
import {
  accessCookie, getShape,
  // getObject, getShape 
} from '../../application/stgoto';

const SemTalkActivityTable = React.lazy(() => import(/* webpackChunkName: "proctable" */
  '../stproctable/SemTalkProcTable'));
const SemTalkSearch = React.lazy(() => import(/* webpackChunkName: "search" */
  '../stsearch/SemTalkSearch'));
const SemTalkObjects = React.lazy(() => import(/* webpackChunkName: "objects" */
  '../stobject/SemTalkObjects'));
const SemTalkNavigation = React.lazy(() => import(/* webpackChunkName: "navigation" */
  '../stnavigation/SemTalkNavigation'));
const SemTalkLinks = React.lazy(() => import(/* webpackChunkName: "links" */
  '../stlinks/SemTalkLinks'));
const SemTalkDiagramTable = React.lazy(() => import(/* webpackChunkName: "diagram" */
  '../stdiagram/SemTalkDiagram'));
// const SemTalkPropertyTable = React.lazy(() => import(/* webpackChunkName: "property" */
//   '../stproperty/SemTalkProperty'));
const SemTalkDocumentTable = React.lazy(() => import(/* webpackChunkName: "document" */
  '../stdocument/SemTalkDocument'));
const SemTalkSettings = React.lazy(() => import(/* webpackChunkName: "settings" */
  '../stsettings/SemTalkSettings'));
// const SemTalkReport = React.lazy(() => import(/* webpackChunkName: "report" */
//   '../streport/SemTalkReport'));
const SemTalkNavigator = React.lazy(() => import(/* webpackChunkName: "navigator" */
  '../stnavigator/SemTalkNavigator'));
const SemTalkListItems = React.lazy(() => import(/* webpackChunkName: "repository" */
  '../stlistitems/SemTalkListItems'));
const SemTalkContext = React.lazy(() => import(/* webpackChunkName: "context" */
  '../stcontext/SemTalkContext'));
// import { svg } from 'd3';

type SemTalkRibbon = any[];

initializeIcons(undefined, { disableWarnings: true });

export interface ISemTalkSvgProps {
  mongo: IMongoOption;
  semservice: ISemService;
  useapi: boolean;
  sharepointrepository: string;
  // context: any;
  // documentUrl: string;
  // service: string;
  // filter: string;
  // token: string;
  // site: string;
  // document: string;
  width: string;
  height: string;
  showContext: boolean;
  showDetails: boolean;
  showProps: boolean;
  // showCon: boolean;
  // showSearchDriven: boolean;
  // searchquery: string;
  // searchmytasks: boolean;
  showtype?: boolean;
  shownodes?: boolean;
  dividetable: boolean;
  showproperties?: boolean;
  shownav?: boolean;
  hidebpmn?: string[];
  hidesimulation?: string[];
  // showPropsGrouped: boolean;
  // showLinks: boolean;
  showDiagram: boolean;
  // showDocument: boolean;
  // showAttachment: boolean;
  // showTeams: boolean;
  // showTasks: boolean;
  showBreadCrumbs: boolean;
  showCommandBar: boolean;
  showToolBar: boolean;
  //showListItems: boolean;
  // showListDocuments: boolean;
  // editDocumentList: boolean;
  // editList: boolean;
  // listitemssite?: string;
  // listitemslist?: string;
  // listitemsquery?: string;
  // listitemscolumns?: string[];
  // listdocumentslibrary?: string;
  // listdocumentssite?: string;
  // listdocumentscolumns?: string[];
  // listdocumentsquery?: string;
  // islist: boolean;
  gotonodes: boolean;
  // goodlist: string[];
  // badlist: string[];
  objroot: string;
  navroot: string;
  views: string;
  nrOfItems?: number | undefined;
  // displayMode?: DisplayMode | undefined;
  displayMode?: any | undefined;
  // graphClient?: MSGraphClient;
  graphClient?: any;
  // updateProperty?: (value: string) => void;
  usegraph: boolean;
  teams?: ISemTalkTeamsOptions;
  azureAD?: ISemTalkAzureOptions;
  defaulttopic?: string;
  portal?: string;
  teamid?: string;
  planid?: string;
  channelid?: string;
  // showBot: boolean;
  // botsecret: string;
  // showWiki: boolean;
  // wikilist?: string | undefined;
  // wikisite?: string | undefined;
  // wikieditlist: boolean;
  // wikiiscombo: boolean;
  isSingleDocument: boolean;
  // navVisible?: boolean;
  // searchVisible?: boolean;
  // propsVisible?: boolean;
  // diagVisible?: boolean;
  // hieVisible?: boolean;
  // reltreeVisible: boolean;
  // teaVisible?: boolean;
  // docVisible?: boolean;
  // procVisible?: boolean;
  // lisVisible?: boolean;
  // wikiVisible?: boolean;
  // detVisible?: boolean;
  // linVisible?: boolean;
  // propGVisible?: boolean;
  // useVisible?: boolean;
  // trendVisible?: boolean;
  // whoVisible?: boolean;
  // conVisible?: boolean;
  // repVisible?: boolean;
  // roleVisible?: boolean;
  // docInfoVisible?: boolean;
  // botVisible?: boolean;
  objIsList?: boolean;
  objIscombo?: boolean;
  objGoodlist?: string;
  objBadlist?: string;
  svgprop: string;
  svghyperlink: string;
  svgrefine: string;
  paneltype: PanelType;
  // proc_showtasks: boolean;
  // proc_showroles: boolean;
  // proc_showrasci: boolean;
  // proc_showcomments: boolean;
  // proc_showattachment: boolean;
  // proc_showdoattachment: boolean;
  // proc_showinput: boolean;
  // proc_showoutput: boolean;
  pivotminwidth: string;
  relationroot: string;
  relationlist: string[];
  tit_property?: string;
  tit_search?: string;
  tit_diagram?: string;
  tit_propWithGroup?: string;
  tit_context?: string;
  tit_details?: string;
  tit_visiodata?: string;
  tit_share?: string;
  tit_hyperlinks?: string;
  tit_navigation?: string;
  tit_issues?: string;
  tit_processtable?: string;
  tit_objects?: string;
  usedialogs: boolean;
  shownavigator: boolean;
  pivotvisible: boolean;
}
export interface ISemTalkSvgState {
  svgpages: IModelAttributeTable[];
  hidePropertyDialog: boolean;
  hideDiagramDialog: boolean;
  hideNavigatorDialog: boolean;
  shownavigator: boolean;
  showpivot: boolean;
  showContext: boolean;
  showSearch: boolean;
  // showPivot: boolean;
  showProcTable: boolean;
  showNav: boolean;
  showHie: boolean;
  // showRelTree: boolean;
  showRep: boolean;
  // showTea: boolean;
  // showRol: boolean;
  // showBot: boolean;
  showListItems: boolean;
  // showListDocuments: boolean;
  // showRec: boolean;
  // showUse: boolean;
  // showTre: boolean;
  // showWho: boolean;
  showDiag: boolean;
  showProp: boolean;
  // usedialogs: boolean;
  showCon: boolean;
  showLinks: boolean;
  // showWiki: boolean;
  // showChart: boolean;
  showDocumentInfo: boolean;
  showDet: boolean;
  showPropG: boolean;
  showSettings: boolean;
  showContextualMenu: boolean;
  ribbon: SemTalkRibbon;
  toolbar: SemTalkRibbon;
  isLoading: string;
}
export enum SemTalkOnlineCommand {
  ShowOptions = "ShowOptions",
  ZoomIn = "ZoomIn",
  ZoomOut = "ZoomOut",
  ZoomFit = "ZoomFit",
  ZoomActual = "ZoomActual",
  GoBack = "GoBack",
  GoForward = "GoForward"
  // SelectLeft = "SelectLeft",
  // SelectRight = "SelectRight",
  // SelectUp = "SelectUp",
  // SelectDown = "SelectDown",
  // Speech = "Speech",
}
export interface ISemTalkSVG {
  ParseMenu(isribbon: boolean, ctrl?: React.Component): { "items": any, "allitems": any };
  ParseToolBar(isribbon: boolean, ctrl?: React.Component): { "items": any, "allitems": any };
  showHelp(page?: string): void;
  DoCommand(cmd: SemTalkOnlineCommand, args: any): boolean;
  isForward(): boolean;
  isBackward(): boolean;
}
export class SemTalkSvg extends React.Component<ISemTalkSvgProps, ISemTalkSvgState> {
  // private selectedShape: string | null = "";
  private selectedPage: string | null = "";
  // private svgurl: string = "";
  private selectedModel: string = "";
  // private _site: string = this.props.semservice.site;
  public callback: Guid;
  private strings: any;
  private res: ResStr;

  private tit_property: string;
  private tit_search: string;
  private tit_diagram: string;
  private tit_propWithGroup: string;
  private tit_context: string;
  private tit_visiodata: string;
  private tit_share: string;
  private tit_navigation: string;
  private tit_hyperlinks: string;
  private tit_issues: string;
  private tit_processtable: string;
  private tit_objects: string;
  // private graphClient: any;


  constructor(props: ISemTalkSvgProps) {
    super(props);
    console.log("showSemTalkSVG");
    //  this.callback = Guid.create();
    this.callback = Guid.create();
    SetApi(this.props.useapi);
    // this.graphClient = this.props.graphClient;
    resetHistory();

    let gl = GetLanguageCode(accessCookie(SemTalkCookie.guilanguage));
    if (gl) {
      this.res = new ResStr(gl);
      if (gl !== SemTalkLanguageCode.German) {
        this.strings = strings_en();
      } else {
        this.strings = strings_de();
      }
    } else {
      this.res = new ResStr(SemTalkLanguageCode.German);
      this.strings = strings_de();
    }


    // let usedialogs = false;
    // let c: string;
    // c = accessCookie(SemTalkCookie.usedialogs);
    // if (c && c === 'true') {
    //   usedialogs = true;
    // }
    this.tit_property = this.res.getResStrListener("STRDLHTMLPROPS");
    this.tit_search = this.res.getResStrListener("STRDLHTMLSEARCH");
    this.tit_diagram = this.res.getResStrListener("STRDLHTMLOUTDIA");
    this.tit_propWithGroup = this.strings.PropsLabel;
    this.tit_context = this.res.getResStrListener("STRCONTEXT");
    this.tit_visiodata = this.res.getResStr("STRDOCPROPERTIES")?.replace("Visio ", "");
    this.tit_share = this.strings.LinksLabel;
    this.tit_navigation = this.res.getResStrListener("STRDLHTMLDIAGR");
    this.tit_hyperlinks = this.res.getResStr("STRHYPERLINKS");
    this.tit_objects = this.res.getResStr("STROBJECTS");
    this.tit_issues = this.strings.Issues;
    this.tit_processtable = this.strings.Process;

    if (this.props.tit_property && this.props.tit_property !=="") this.tit_property = this.props.tit_property;
    if (this.props.tit_search && this.props.tit_search !=="") this.tit_search = this.props.tit_search;
    if (this.props.tit_diagram && this.props.tit_diagram !=="") this.tit_diagram = this.props.tit_diagram;
    if (this.props.tit_propWithGroup && this.props.tit_propWithGroup !=="") this.tit_propWithGroup = this.props.tit_propWithGroup;
    if (this.props.tit_context && this.props.tit_context !=="") this.tit_context = this.props.tit_context;
    if (this.props.tit_visiodata && this.props.tit_visiodata !=="") this.tit_visiodata = this.props.tit_visiodata;
    if (this.props.tit_share && this.props.tit_share !=="") this.tit_share = this.props.tit_share;
    if (this.props.tit_navigation && this.props.tit_navigation !=="") this.tit_navigation = this.props.tit_navigation;
    if (this.props.tit_hyperlinks && this.props.tit_hyperlinks !=="") this.tit_hyperlinks = this.props.tit_hyperlinks;
    if (this.props.tit_objects && this.props.tit_objects !=="") this.tit_objects = this.props.tit_objects;
    if (this.props.tit_issues && this.props.tit_issues !=="") this.tit_issues = this.props.tit_issues;
    if (this.props.tit_processtable && this.props.tit_processtable !=="") this.tit_processtable = this.props.tit_processtable;

    this.state = {
      svgpages: [],
      hidePropertyDialog: true,
      hideDiagramDialog: true,
      hideNavigatorDialog: true,
      showListItems: false,
      shownavigator: this.props.shownavigator,
      showpivot: this.props.pivotvisible,
      showContext: false,
      showSearch: false,
      showProcTable: false,
      showNav: false,
      showHie: false,
      // showRelTree: false,
      showRep: false,
      // showTea: false,
      // showRol: false,
      // showBot: false,
      //showListItems: false,
      // showListDocuments: false,
      // showRec: false,
      // showUse: false,
      // showTre: false,
      // showWho: false,
      showDiag: false,
      showProp: true,
      // usedialogs: usedialogs,
      showCon: false,
      showLinks: false,
      // showWiki: false,
      // showChart: false,
      showDocumentInfo: false,
      showDet: false,
      showPropG: false,
      showSettings: false,
      showContextualMenu: false,
      ribbon: [],
      toolbar: [],
      isLoading: ""
    };
    // this.testsvg();
  }


  private svgContainer: any;
  // private svgObject: any;
  private svgPaper: any;
  private rootPaper: any;
  private groupcontext: string = "v:groupContext";
  private selectionRect: any;
  private hoveredShape: any;
  private panZoom: any;

  public ParseMenu0 = (isribbon: boolean, ctrl?: React.Component): { "items": any, "allitems": any } => {
    // let ribbon: SemTalkRibbon = ribbontemplate.ribbon;
    let ribbon1: any = ribbontemplate;
    let ribbon: SemTalkRibbon = ribbon1.default.ribbon;
    if (!isribbon) {
      ribbon = ribbon1.default.toolbar;
    }
    let allitems: any = {};
    let items: any[] = [];
    // const sem = this.state.semtalk;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    if (!ctrl) ctrl = this;
    if (!ribbon) ribbon = this.state.ribbon;
    if (this.res) {
      let parseMenuItem = (ribbonitem: any): any => {
        let nitem: any = {};
        if (ribbonitem["key"]) {
          nitem["key"] = ribbonitem["key"];
          allitems[nitem["key"]] = nitem;
          if (ribbonitem["text"] && ribbonitem["text"].length > 0) {
            nitem["text"] = this.res.getResStr(ribbonitem["text"]).replace("&", "").replace(":", "");
          } else {
            if (ribbonitem["textL"] && ribbonitem["textL"].length > 0) {
              nitem["text"] = this.res.getResStrListener(ribbonitem["textL"]).replace("&", "").replace(":", "");
            }
          }
          if (ribbonitem["hidden"]) {
            nitem["hidden"] = ribbonitem["hidden"];
          }
          // if (ribbonitem["role"]) {
          //   let role = ribbonitem["role"];
          //   switch (role) {
          //     case SemTalkRole.admin: {
          //       nitem["hidden"] = this.props.role !== role;
          //       break;
          //     }
          //     case SemTalkRole.metamodel: {
          //       nitem["hidden"] = (this.props.role !== role &&
          //         this.props.role !== SemTalkRole.admin);
          //       break;
          //     }
          //     case SemTalkRole.editor: {
          //       nitem["hidden"] = (this.props.role !== role &&
          //         this.props.role !== SemTalkRole.metamodel &&
          //         this.props.role !== SemTalkRole.admin);
          //       break;
          //     }
          //     default: {
          //       nitem["hidden"] = (this.props.role !== role &&
          //         this.props.role !== SemTalkRole.admin);
          //       break;
          //     }
          //   }
          // }
          if (ribbonitem["cacheKey"]) nitem["cacheKey"] = ribbonitem["cacheKey"];
          if (ribbonitem["split"]) nitem["split"] = true;
          if (ribbonitem["iconOnly"]) nitem["iconOnly"] = true;
          if (ribbonitem["iconProps"]) nitem["iconProps"] = ribbonitem["iconProps"];
          if (ribbonitem["subMenuProps"]) {
            let subitems: any = [];
            let items1 = ribbonitem["subMenuProps"]["items"];
            // eslint-disable-next-line guard-for-in
            for (let sitemindex in items1) {
              let sitem = items1[sitemindex];
              let nsubitem = parseMenuItem(sitem);
              if (!nsubitem["hidden"]) {
                subitems.push(nsubitem);
              }
            }
            nitem["subMenuProps"] = { "items": subitems };
          }
          if (ribbonitem["onClickCallback"]) {
            nitem["onClick"] = () => {
              this.DoCommand(ribbonitem["onClickCallback"], {});
            };
          }
          // if (ribbonitem["onClickAddOn"]) {
          //   nitem["onClick"] = () => {
          //     this.DoCommand(SemTalkOnlineCommand.AddonCommand, { "command": ribbonitem["onClickAddOn"] });
          //   };
          // }
          if (ribbonitem["onClickState"]) {
            nitem["onClick"] = () => {
              let sname: string = ribbonitem["onClickState"];
              let s: any = {};
              s[sname] = true;
              // if (ctrl) ctrl.setState(s);
              this.setState(s);
            };
          }
          if (ribbonitem["itemType"] && ribbonitem["itemType"] === 1) {
            nitem["name"] = "-";
            nitem["disabled"] = true;
            nitem["itemType"] = ContextualMenuItemType.Divider;
          }
        }
        return nitem;
      };
      for (let ribbonitem of ribbon) {
        try {
          let mitem = parseMenuItem(ribbonitem);
          if (mitem) {
            if (mitem["hidden"]) {
              // console.debug(mitem);
            } else {
              items.push(mitem);
            }
          }
        } catch (e) {
          console.debug(e);
        }
      }
    }
    return { "items": items, "allitems": allitems };
  }
  public ParseMenu = (_isribbon: boolean, _ctrl?: React.Component): { "items": any, "allitems": any } => {
    const toolbar = this.ParseMenu0(false, this);
    const allitems0 = toolbar["allitems"];
    let getToolbarItem = (key: string, onclick: undefined | (() => void)): any => {
      const nmenu = allitems0[key];
      if (onclick && nmenu) nmenu["onClick"] = onclick;
      return nmenu;
    };
    // let ribbon: SemTalkRibbon = this.state.ribbon;
    // if (!isribbon) {
    //   ribbon = this.state.toolbar;
    //   // ribbon = require("./toolbar.json");
    // }

    // Hier sollte man auch ggf die ShortCuts setzen
    // Export Import aus subtask DLL erweitern

    let c: string;

    let allitems: any = {};
    let items: any[] = [];

    let pivotItem =
    {
      key: 'PivotItem',
      name: this.strings.DetailsLabel,
      cacheKey: 'myCacheKeyPivotMenu', // changing this key will invalidate this items cache
      iconProps: {
        iconName: 'FileImage'
      },
      onClick: (): void => {
        setCookie(SemTalkPortalCookie.pivotvisible, !this.state.showpivot);
        this.setState({ showpivot: !this.state.showpivot });
      }
    };
    allitems[pivotItem.key] = pivotItem;
    items.push(pivotItem);

    let showzoo: boolean = true;
    c = accessCookie(SemTalkPortalCookie.showzoom);
    if (c === 'false') { showzoo = false; }
    if (showzoo) {
      let it1 = getToolbarItem("zoomin", undefined);
      if (it1) items.push(it1);
      let it2 = getToolbarItem("zoomout", undefined);
      if (it2) items.push(it2);
    }
    let bw = getToolbarItem("goBack", undefined);
    if (bw) items.push(bw);
    let fw = getToolbarItem("goForward", undefined);
    if (fw) items.push(fw);

    let shownav: boolean = true;
    c = accessCookie(SemTalkPortalCookie.shownav);
    if (c === 'false') { shownav = false; }
    if (shownav) {
      let navItem =
      {
        key: 'NavItem',
        name: this.tit_navigation,
        cacheKey: 'myCacheKeyNavMenu', // changing this key will invalidate this items cache
        iconProps: {
          iconName: 'DOM'
        },
        onClick: (): void => {
          this.setState({ showNav: true });
        },
      };
      allitems[navItem.key] = navItem;
      items.push(navItem);
    }

    let showsearch: boolean = true;
    c = accessCookie(SemTalkPortalCookie.showsearch);
    if (c === 'false') { showsearch = false; }
    if (showsearch) {
      let searchItem =
      {
        key: 'search',
        name: this.tit_search,
        cacheKey: 'myCacheKeySearch', // changing this key will invalidate this items cache
        iconProps: {
          iconName: 'Search'
        },
        onClick: (): void => {
          this.setState({ showSearch: true });
        }
      };
      allitems[searchItem.key] = searchItem;
      items.push(searchItem);
    }

    let showprops: boolean = true;
    c = accessCookie(SemTalkPortalCookie.showprops);
    if (c === 'false') { showprops = false; }
    if (showprops) {
      let propsItem =
      {
        key: 'propItem',
        name: this.tit_property,
        iconProps: {
          iconName: 'FileImage'
        },
        onClick: (): void => {
          this.setState({ hidePropertyDialog: false });
        },
      };
      allitems[propsItem.key] = propsItem;
      items.push(propsItem);
    }

    let showdia: boolean = true;
    c = accessCookie(SemTalkPortalCookie.showdia);
    if (c === 'false') { showdia = false; }
    if (showdia) {
      let diagItem =
      {
        key: 'Diag',
        name: this.tit_diagram,
        cacheKey: 'myCacheKeyDiag', // changing this key will invalidate this items cache
        iconProps: {
          iconName: 'ExternalTFVC'
        },
        onClick: (): void => {
          this.setState({ showDiag: true });
        }
      };
      allitems[diagItem.key] = diagItem;
      items.push(diagItem);
    }

    let showhie: boolean = false;
    c = accessCookie(SemTalkPortalCookie.showhie);
    if (c === 'true') { showhie = true; }
    if (showhie) {
      let hieItem =
      {
        key: 'Hie',
        name: this.tit_objects,
        cacheKey: 'myCacheKeyHie', // changing this key will invalidate this items cache
        iconProps: {
          iconName: 'Folder'
        },
        onClick: (): void => {
          this.setState({ showHie: true });
        }
      };
      allitems[hieItem.key] = hieItem;
      items.push(hieItem);
    }

    // private relTreeItem =
    //   {
    //     key: 'RelTree',
    //     name: this.props.strings.RelationTree,
    //     cacheKey: 'myCacheKeyRelTree', // changing this key will invalidate this items cache
    //     iconProps: {
    //       iconName: 'Folder'
    //     },
    //     onClick: (): void => {
    //       this.setState({ showRelTree: true });
    //     }
    //   };
    // private teaItem =
    //   {
    //     key: 'Tea',
    //     name: this.props.strings.Planner,
    //     cacheKey: 'myCacheKeyTea', // changing this key will invalidate this items cache
    //     iconProps: {
    //       iconName: 'TeamsLogo'
    //     },
    //     onClick: (): void => {
    //       this.setState({ showTea: true });
    //     }
    //   };
    // private docItem =
    //   {
    //     key: 'Doc',
    //     name: this.props.dTitle,
    //     cacheKey: 'myCacheKeyDoc', // changing this key will invalidate this items cache
    //     iconProps: {
    //       iconName: 'DocsLogoInverse'
    //     },
    //     onClick: (): void => {
    //       this.setState({ showListDocuments: true });
    //     }
    //   };

    let showpro: boolean = true;
    c = accessCookie(SemTalkPortalCookie.showpro);
    if (c === 'false') { showpro = false; }
    if (showpro) {
      let procItem =
      {
        key: 'Proc',
        name: this.tit_processtable,
        cacheKey: 'myCacheKeyProc', // changing this key will invalidate this items cache
        iconProps: {
          iconName: 'Tiles'
        },
        iconOnly: false,
        onClick: (): void => {
          this.setState({ showProcTable: true });
        }
      };
      allitems[procItem.key] = procItem;
      items.push(procItem);
    }
    let showrepo: boolean = false;
    c = accessCookie(SemTalkPortalCookie.showlistitems);
    if (c === 'false') { showrepo = false; }
    if (showrepo) {
      let repoItem =
      {
        key: 'Repo',
        name: this.tit_issues,
        cacheKey: 'myCacheKeyRepo', // changing this key will invalidate this items cache
        iconProps: {
          iconName: 'Tiles'
        },
        iconOnly: false,
        onClick: (): void => {
          this.setState({ showListItems: true });
        }
      };
      allitems[repoItem.key] = repoItem;
      items.push(repoItem);
    }

    // private lisItem =
    //   {
    //     key: 'Lis',
    //     name: this.props.listTitle,
    //     cacheKey: 'myCacheKeyLis', // changing this key will invalidate this items cache
    //     iconProps: {
    //       iconName: 'Backlog'
    //     },
    //     onClick: (): void => {
    //       this.setState({ showListItems: true });
    //     }
    //   };
    // private wikiItem =
    //   {
    //     key: 'Wiki',
    //     name: this.props.strings.WikiLabel,
    //     cacheKey: 'myCacheKeyWiki', // changing this key will invalidate this items cache
    //     iconProps: {
    //       iconName: 'ReadingMode'
    //     },
    //     onClick: (): void => {
    //       this.setState({ showWiki: true });
    //     }
    //   };
    // let showdet: boolean = false;
    // c = accessCookie(SemTalkPortalCookie.showdetails);
    // if (c === 'true') { showdet = true; }
    // if (showdet) {
    //   let detItem =
    //   {
    //     key: 'Det',
    //     name: this.strings.DetailsLabel,
    //     cacheKey: 'myCacheKeyDet', // changing this key will invalidate this items cache
    //     iconProps: {
    //       iconName: 'FileImage'
    //     },
    //     onClick: (): void => {
    //       this.setState({ showDet: true });
    //     }
    //   };
    //   allitems[detItem.key] = detItem;
    //   items.push(detItem);
    // }

    // let showlin: boolean = false;
    // c = accessCookie(SemTalkPortalCookie.pivot_showlin);
    // if (c === 'true') { showlin = true; }
    // if (showlin) {
    //   let linItem =
    //   {
    //     key: 'Lin',
    //     name: this.tit_share,
    //     cacheKey: 'myCacheKeyLinks', // changing this key will invalidate this items cache
    //     iconProps: {
    //       iconName: 'FileASPX'
    //     },
    //     onClick: (): void => {
    //       this.setState({ showLinks: true });
    //     }
    //   };
    //   allitems[linItem.key] = linItem;
    //   items.push(linItem);
    // }

    let showcon: boolean = false;
    c = accessCookie(SemTalkPortalCookie.showcon);
    if (c === 'true') { showcon = true; }
    if (showcon) {
      let conItem =
      {
        key: 'ConG',
        name: this.tit_context,
        cacheKey: 'myCacheKeyConG', // changing this key will invalidate this items cache
        iconProps: {
          iconName: 'FileImage'
        },
        onClick: (): void => {
          this.setState({ showCon: true });

        }
      };
      allitems[conItem.key] = conItem;
      items.push(conItem);
    }

    // private propGItem =
    //   {
    //     key: 'PropG',
    //     name: this.props.tit_propWithGroup,
    //     cacheKey: 'myCacheKeyPropG', // changing this key will invalidate this items cache
    //     iconProps: {
    //       iconName: 'FileImage'
    //     },
    //     onClick: (): void => {
    //       this.setState({ showPropG: true });
    //     }
    //   };
    // private useItem =
    //   {
    //     key: 'Use',
    //     name: this.props.strings.UsedDocuments,
    //     cacheKey: 'myCacheKeyUse', // changing this key will invalidate this items cache
    //     iconProps: {
    //       iconName: 'Recent'
    //     },
    //     iconOnly: true,
    //     onClick: (): void => {
    //       this.setState({ showUse: true });
    //     }
    //   };
    // private trendItem =
    //   {
    //     key: 'Tre',
    //     name: this.props.strings.TrendingDocuments,
    //     cacheKey: 'myCacheKeyTre', // changing this key will invalidate this items cache
    //     iconProps: {
    //       iconName: 'Trending12'
    //     },
    //     iconOnly: true,
    //     onClick: (): void => {
    //       this.setState({ showTre: true });
    //     }
    //   };
    // private whoItem =
    //   {
    //     key: 'Who',
    //     name: this.props.strings.People,
    //     cacheKey: 'myCacheKeyWho', // changing this key will invalidate this items cache
    //     iconProps: {
    //       iconName: 'UserFollowed'
    //     },
    //     iconOnly: true,
    //     onClick: (): void => {
    //       this.setState({ showWho: true });
    //     }
    //   };

    let showrep: boolean = false;
    c = accessCookie(SemTalkPortalCookie.showrep);
    if (c === 'true') { showrep = true; }
    if (showrep) {
      let repItem =
      {
        key: 'Rep',
        name: this.res.getResStr("STRREPORT"),
        cacheKey: 'myCacheKeyRep', // changing this key will invalidate this items cache
        iconProps: {
          iconName: 'ReportDocument'
        },
        onClick: (): void => {
          this.setState({ showRep: true });
        }
      };
      allitems[repItem.key] = repItem;
      items.push(repItem);
    }
    // private roleItem =
    //   {
    //     key: 'Rol',
    //     name: this.props.strings.RoleAssignment,
    //     cacheKey: 'myCacheKeyRol', // changing this key will invalidate this items cache
    //     iconProps: {
    //       iconName: 'TeamsLogo'
    //     },
    //     onClick: (): void => {
    //       this.setState({ showRol: true });
    //     }
    //   };

    let showdocinfo: boolean = false;
    c = accessCookie(SemTalkPortalCookie.showdocinfo);
    if (c === 'true') { showdocinfo = true; }
    if (showdocinfo) {
      let docInfoItem =
      {
        key: 'DocInfo',
        name: this.tit_visiodata,
        cacheKey: 'myCacheKeyDocInfo', // changing this key will invalidate this items cache
        iconProps: {
          iconName: 'DocumentManagement'
        },
        onClick: (): void => {
          this.setState({ showDocumentInfo: true });
        }
      };
      allitems[docInfoItem.key] = docInfoItem;
      items.push(docInfoItem);
    }

    // private botItem =
    //   {
    //     key: 'Bot',
    //     name: this.props.strings.Bot,
    //     cacheKey: 'myCacheKeyBot', // changing this key will invalidate this items cache
    //     iconProps: {
    //       iconName: 'Chat'
    //     },
    //     onClick: (): void => {
    //       this.setState({ showBot: true });
    //     }
    //   };

    // const sem = this.state.semtalk;
    // if (!ctrl) ctrl = this;
    // if (!ribbon) ribbon = this.state.ribbon;
    // if (sem) {
    //   let parseMenuItem = (ribbonitem: any): any => {
    //     let nitem: any = {};
    //     if (ribbonitem["key"]) {
    //       nitem["key"] = ribbonitem["key"];
    //       allitems[nitem["key"]] = nitem;
    //       if (ribbonitem["text"] && ribbonitem["text"].length > 0) {
    //         nitem["text"] = sem.getResStr(ribbonitem["text"]).replace("&", "").replace(":", "");
    //       } else {
    //         if (ribbonitem["textL"] && ribbonitem["textL"].length > 0) {
    //           nitem["text"] = sem.getResStrListener(ribbonitem["textL"]).replace("&", "").replace(":", "");
    //         }
    //       }
    //       if (ribbonitem["hidden"]) {
    //         nitem["hidden"] = ribbonitem["hidden"];
    //       }
    //       if (ribbonitem["role"]) {
    //         let role = ribbonitem["role"];
    //         switch (role) {
    //           case SemTalkRole.admin: {
    //             nitem["hidden"] = this.props.role !== role;
    //             break;
    //           }
    //           case SemTalkRole.metamodel: {
    //             nitem["hidden"] = (this.props.role !== role &&
    //               this.props.role !== SemTalkRole.admin);
    //             break;
    //           }
    //           case SemTalkRole.editor: {
    //             nitem["hidden"] = (this.props.role !== role &&
    //               this.props.role !== SemTalkRole.metamodel &&
    //               this.props.role !== SemTalkRole.admin);
    //             break;
    //           }
    //           default: {
    //             nitem["hidden"] = (this.props.role !== role &&
    //               this.props.role !== SemTalkRole.admin);
    //             break;
    //           }
    //         }
    //       }
    //       if (ribbonitem["cacheKey"]) nitem["cacheKey"] = ribbonitem["cacheKey"];
    //       if (ribbonitem["split"]) nitem["split"] = true;
    //       if (ribbonitem["iconOnly"]) nitem["iconOnly"] = true;
    //       if (ribbonitem["iconProps"]) nitem["iconProps"] = ribbonitem["iconProps"];
    //       if (ribbonitem["subMenuProps"]) {
    //         let subitems: any = [];
    //         let items1 = ribbonitem["subMenuProps"]["items"];
    //         for (let sitemindex in items1) {
    //           let sitem = items1[sitemindex];
    //           let nsubitem = parseMenuItem(sitem);
    //           if (!nsubitem["hidden"]) {
    //             subitems.push(nsubitem);
    //           }
    //         }
    //         nitem["subMenuProps"] = { "items": subitems };
    //       }
    //       if (ribbonitem["onClickCallback"]) {
    //         nitem["onClick"] = () => {
    //           this.DoCommand(ribbonitem["onClickCallback"], {});
    //         };
    //       }
    //       if (ribbonitem["onClickAddOn"]) {
    //         nitem["onClick"] = () => {
    //           this.DoCommand(SemTalkOnlineCommand.AddonCommand, { "command": ribbonitem["onClickAddOn"] });
    //         };
    //       }
    //       if (ribbonitem["onClickState"]) {
    //         nitem["onClick"] = () => {
    //           let sname: string = ribbonitem["onClickState"];
    //           let s: any = {};
    //           s[sname] = true;
    //           // if (ctrl) ctrl.setState(s);
    //           this.setState(s);
    //         };
    //       }
    //       if (ribbonitem["itemType"] && ribbonitem["itemType"] === 1) {
    //         nitem["name"] = "-";
    //         nitem["disabled"] = true;
    //         nitem["itemType"] = ContextualMenuItemType.Divider;
    //       }
    //     }
    //     return nitem;
    //   };
    //   for (let ribbonitem of ribbon) {
    //     try {
    //       let mitem = parseMenuItem(ribbonitem);
    //       if (mitem) {
    //         if (mitem["hidden"]) {
    //           // console.debug(mitem);
    //         } else {
    //           items.push(mitem);
    //         }
    //       }
    //     } catch (e) {
    //       console.debug(e);
    //     }
    //   }
    // }
    return { "items": items, "allitems": allitems };
  }
  public ParseToolBar = (_isribbon: boolean, _ctrl?: React.Component): { "items": any, "allitems": any } => {
    const toolbar = this.ParseMenu0(false, this);
    const allitems0 = toolbar["allitems"];
    let getToolbarItem = (key: string, onclick: undefined | (() => void)): any => {
      const nmenu = allitems0[key];
      nmenu["iconOnly"] = true;
      if (onclick && nmenu) nmenu["onClick"] = onclick;
      return nmenu;
    };
    let c: string;

    let allitems: any = {};
    let items: any[] = [];

    let pivotItem =
    {
      key: 'PivotItem',
      name: this.strings.DetailsLabel,
      cacheKey: 'myCacheKeyPivotMenu', // changing this key will invalidate this items cache
      iconOnly: true,
      iconProps: {
        iconName: 'FileImage'
      },
      onClick: (): void => {
        setCookie(SemTalkPortalCookie.pivotvisible, !this.state.showpivot);
        this.setState({ showpivot: !this.state.showpivot });
      }
    };
    allitems[pivotItem.key] = pivotItem;
    items.push(pivotItem);

    let showzoo: boolean = true;
    c = accessCookie(SemTalkPortalCookie.showzoom);
    if (c === 'false') { showzoo = false; }
    if (showzoo) {
      let it1 = getToolbarItem("zoomin", undefined);
      if (it1) items.push(it1);
      let it2 = getToolbarItem("zoomout", undefined);
      if (it2) items.push(it2);
    }
    let bw = getToolbarItem("goBack", undefined);
    if (bw) items.push(bw);
    let fw = getToolbarItem("goForward", undefined);
    if (fw) items.push(fw);

    let shownav: boolean = true;
    c = accessCookie(SemTalkPortalCookie.shownav);
    if (c === 'false') { shownav = false; }
    if (shownav) {
      let navItem =
      {
        key: 'NavItem',
        name: this.tit_navigation,
        cacheKey: 'myCacheKeyNavMenu', // changing this key will invalidate this items cache
        iconOnly: true,
        iconProps: {
          iconName: 'DOM'
        },
        onClick: (): void => {
          this.setState({ showNav: true });
        },
      };
      allitems[navItem.key] = navItem;
      items.push(navItem);
    }

    let showsearch: boolean = true;
    c = accessCookie(SemTalkPortalCookie.showsearch);
    if (c === 'false') { showsearch = false; }
    if (showsearch) {
      let searchItem =
      {
        key: 'search',
        name: this.tit_search,
        cacheKey: 'myCacheKeySearch', // changing this key will invalidate this items cache
        iconOnly: true,
        iconProps: {
          iconName: 'Search'
        },
        onClick: (): void => {
          this.setState({ showSearch: true });
        }
      };
      allitems[searchItem.key] = searchItem;
      items.push(searchItem);
    }


    return { "items": items, "allitems": allitems };
  }

  private menuItems: IContextualMenuItem[] = [];
  private onShowContextualMenu = async (_cell: any, ev: React.MouseEvent<HTMLElement>, objid: number) => {

    ev.preventDefault(); // don't navigate
    let menu: any[] = [];
    let showprops: boolean = true;
    let c = accessCookie(SemTalkPortalCookie.showprops);
    if (c === 'false') { showprops = false; }
    if (showprops) {
      let propsItem =
      {
        key: 'propItem',
        name: this.tit_property,
        iconProps: {
          iconName: 'FileImage'
        },
        onClick: async () => {
          await gotoObject(objid);
          // await gotoNode(0, objid, getDiagram(), cell.shapeid);
          if (!this.state.showpivot) {
            this.setState({ hidePropertyDialog: false });
          }
        },
      };
      menu.push(propsItem);
    }
    let proptable = await Links(objid);
    //let proptable: IPropertyTable[] = [];
    let proptable2 = await InvLinks(objid);
    
    let model = getModel();
    for (const element of proptable2) {
      if(element.PropName==="InformationType"&& element.PropValueModel===model){
        proptable = [];
        if(element.PropName==="InformationType"){
          let pt = await Links(element.ObjectID);
          for (let i = 0; i < pt.length; i++)
            {
              if(pt[i].PropName!=="InformationType" && !proptable.includes(pt[i])) {
                proptable.push(pt[i]);
             } 
           };
        } 
        
      }
    } 
    let pages = proptable.filter(p => p.PropType === "Page");
    for (let p of pages) {
      let cap = p.PropValueCaption;
      if (cap && cap.length > 0 && cap.indexOf("/") > 0 && cap.indexOf("http") === 0) {
        cap = cap.substr(cap.lastIndexOf("/") + 1);
      }
      if (cap && cap.length > 0 && cap.indexOf("#") > 0) {
        cap = cap.substring(cap.indexOf("#") + 1);
      }
      let pItem =
      {
        key: 'pageItem' + p.ID,
        name: cap,
        iconProps: {
          iconName: 'Down'
        },
        onClick: async () => {
          await gotoDocument(parseInt(p.PropValueName));
        },
      };
      menu.push(pItem);
    }
    let attachments = proptable.filter(p => p.PropType === "Attachment");
    for (let p of attachments) {
      let hn: any = p.PropValue;
      let cap = p.PropValueCaption;
      if (cap === null) {
        cap = hn;
      }
      let pItem =
      {
        key: 'attItem' + p.ID,
        name: cap,
        iconProps: {
          iconName: 'Link'
        },
        onClick: (): void => {
          const link: any = document.createElement('a');
          link.href = hn;
          link.setAttribute('target', "_blank");
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        },
      };
      menu.push(pItem);
    }
    this.menuItems = menu;
    this.setState({ showContextualMenu: true });
  }

  private onShowDiagramContextualMenu = async (_cell: any, ev: React.MouseEvent<HTMLElement>, objid: number) => {
    const res = this.ParseMenu0(true, this);
    const allitems = res["allitems"];
    let getMenuItem = (key: string, onclick: undefined | (() => void)): any => {
      const nmenu = allitems[key];
      if (onclick && nmenu) nmenu["onClick"] = onclick;
      return nmenu;
    };
    ev.preventDefault(); // don't navigate
    let menu: any[] = [];
    let proptable = await Links(objid);
    let pages = proptable.filter(p => p.PropName === "Aufwärts" || p.PropName === "Go Up");
    for (let p of pages) {
      let cap = p.PropValueCaption;
      if (cap && cap.length > 0 && cap.indexOf("/") > 0 && cap.indexOf("http") === 0) {
        cap = cap.substr(cap.lastIndexOf("/") + 1);
      }
      if (cap && cap.length > 0 && cap.indexOf("#") > 0) {
        cap = cap.substring(cap.indexOf("#") + 1);
      }
      let pItem =
      {
        key: 'pageItem' + p.ID,
        name: cap,
        iconProps: {
          iconName: 'Up'
        },
        onClick: async () => {
          let nn = await NodeNames(parseInt(p.PropValueName));
          for (let n of nn) {
            await gotoNode(n.ID, n.Object, n.NodePage, n.NodeShape);
            return;
          }
        },
      };
      menu.push(pItem);
    }
    pages = proptable.filter(p => p.PropType === "Page");
    for (let p of pages) {
      let cap = p.PropValueCaption;
      if (cap && cap.length > 0 && cap.indexOf("/") > 0 && cap.indexOf("http") === 0) {
        cap = cap.substr(cap.lastIndexOf("/") + 1);
      }
      if (cap && cap.length > 0 && cap.indexOf("#") > 0) {
        cap = cap.substring(cap.indexOf("#") + 1);
      }
      let pItem =
      {
        key: 'pageItem' + p.ID,
        name: cap,
        iconProps: {
          iconName: 'Down'
        },
        onClick: async () => {
          await gotoDocument(parseInt(p.PropValueName));
        },
      };
      menu.push(pItem);
    }
    menu.push({
      "key": "dividerOptions",
      "name": "-",
      "itemType": 1
    });
    menu.push(getMenuItem('zoomPage', undefined));

    if (!this.props.showCommandBar) {
      let sItem =
      {
        "key": "settings1",
        "name": this.res.getResStr("STROPTIONS"),
        "textL": "",
        "iconProps": {
          "iconName": "Settings"
        },
        onClick: () => {
          this.DoCommand(SemTalkOnlineCommand.ShowOptions, {});
        }
      };
      menu.push(sItem);
    }
    this.menuItems = menu;
    this.setState({ showContextualMenu: true });
  }

  private onHideContextualMenu = () => {
    this.setState({ showContextualMenu: false });
  }

  private popupMenuRef: any = null; //React.useRef(null);
  private slbadlist = ["Swimlane", "Pool / Lane", "Verantwortlichkeitsbereich"];
  private onShowContextMenu = async (e: any) => {
    if (this.props.semservice.context) {
      this.popupMenuRef = e.nativeEvent;
    } else {
      this.popupMenuRef = e;
    }
    e.preventDefault();
    if (this.hoveredShape) {
      let shape = this.parseShapeElement(this.hoveredShape);
      if (!shape) return;
      let diagid = getDiagram();
      let nd = await FindNodeByShape(diagid, shape.shapeid);
      if (nd) {
        if (shape.type && this.slbadlist.find(x => (shape.type as string).startsWith(x))) {
          diagid = getDiagram();
          await this.onShowDiagramContextualMenu(null, e, diagid);
        } else {
          this.selectionRect.attr({
            x: shape.x,
            y: shape.y,
            width: shape.width,
            height: shape.height,
            fill: "none",
            stroke: "red",
            strokeWidth: 9
          });
          await this.onShowContextualMenu(shape, e, nd.Object);
          // await gotoNode(nd.NodeModel, nd.Object, nd.NodePage, nd.NodeShape);
        }
      }
    } else {
      let diagid = getDiagram();
      await this.onShowDiagramContextualMenu(null, e, diagid);
    }
  }
  private _onClose = (s: any): void => {
    this.setState(s);
  }
  private onRenderFooterContent = (): JSX.Element => {
    return (
      <div>
      </div>);
  }
  private onRenderSettingsFooterContent = (): JSX.Element => {
    return (
      <div>
        <PrimaryButton onClick={this.reloadFromSettingsPanel} styles={{ root: { marginRight: 8 } }}>{this.res.getResStr("STRDLGCMDOK")}</PrimaryButton>
        <DefaultButton onClick={() => { this._onClose({ showSettings: false }); }} styles={{ root: { marginRight: 8 } }}>{this.res.getResStr("STRDLGCMDCA")}</DefaultButton>
        <DefaultButton onClick={() => { this.showHelp("Tools_SemTalk_Options"); }} styles={{ root: { marginRight: 8 } }}>{this.res.getResStr("STRHELP")}</DefaultButton>
      </div>);
  }
  private reloadFromSettingsPanel = () => {
    this._onClose({ showSettings: false });
    window.location.assign(document.URL);
  }
  public render(): React.ReactElement<ISemTalkSvgProps> {
    // let tit: string = strings.PropsLabel;
    let repo = this.props.mongo.repository;
    if (repo === "repository") repo = "Default";
    if (!this.props.mongo.usemongo && this.props.sharepointrepository) {
      repo = this.props.sharepointrepository;
      repo = repo.replace("/sites/", "");
      repo = repo.replace(":/", "/");
      repo = repo.replace(":/", "");
    }

    let suspfallback = <div />;
    let wi = (window as any).innerWidth;
    let contextwidth = String(wi / 3) + "px";

    return (
      <Fabric>
        <div>
          {this.props.showCommandBar &&
            <SemTalkCommandBar
              callback={this}
              res={this.res}
              strings={this.strings}
            />
          }
        </div>
        <div>
          {this.props.showToolBar &&
            <SemTalkToolBar
              callback={this}
              res={this.res}
              strings={this.strings}
              showmenubar={this.props.showCommandBar}
            />
          }
        </div>
        <div>
          {this.props.showBreadCrumbs &&
            <SemTalkBreadCrumbs semservice={this.props.semservice} />
          }
        </div>
        <div className={styles.semTalkSvg}>
          <Stack>
            {this.state.shownavigator &&
              <StackItem>
                <Suspense fallback={suspfallback}>
                  <SemTalkNavigator callback={this} res={this.res} />
                </Suspense>
              </StackItem>
            }
            {this.state.showpivot &&
              <StackItem>
                <SemTalkPivot
                  callback={this}
                  res={this.res}
                  semservice={this.props.semservice}
                  gotonodes={this.props.gotonodes}
                  // showDetails={this.props.showDetails}
                  // showDocument={this.props.showDocument}
                  // showProps={this.props.showProps}
                  // showContext={this.props.showCon}
                  // showSearchDriven={this.props.showSearchDriven}
                  // searchquery={this.props.searchquery}
                  // searchmytasks={this.props.searchmytasks}
                  // showtype={this.props.showtype}
                  // shownodes={this.props.shownodes}
                  // showproperties={this.props.showproperties}
                  navigateproperties={true}
                  // shownav={this.props.shownav}
                  hidebpmn={this.props.hidebpmn}
                  hidesimulation={this.props.hidesimulation}
                  // showPropsGrouped={this.props.showPropsGrouped}
                  // showLinks={this.props.showLinks}
                  // showDiagram={this.props.showDiagram}
                  // showAttachment={this.props.showAttachment}
                  // showTeams={this.props.showTeams}
                  // showTasks={this.props.showTasks}
                  // showListItems={this.props.showListItems}
                  // showListDocuments={this.props.showListDocuments}
                  // listitemssite={this.props.listitemssite}
                  // listitemslist={this.props.listitemslist}
                  // listitemsquery={this.props.listitemsquery}
                  // listitemscolumns={this.props.listitemscolumns}
                  // editList={this.props.editList}
                  // listdocumentslibrary={this.props.listdocumentslibrary}
                  // listdocumentssite={this.props.listdocumentssite}
                  // listdocumentsquery={this.props.listdocumentsquery}
                  // listdocumentscolumns={this.props.listdocumentscolumns}
                  // editDocumentList={this.props.editDocumentList}
                  views={this.props.views}
                  // goodlist={this.props.goodlist}
                  // badlist={this.props.badlist}
                  // listFilter={fil}
                  // addnew={true}
                  // graphClient={this.props.graphClient}
                  // usegraph={this.props.usegraph}
                  // goto_context={getContext()}
                  // teamid={this.props.teamid}
                  // portal={this.props.portal}
                  // planid={this.props.planid}
                  // channelid={this.props.channelid}
                  // showBot={this.props.showBot}
                  // botsecret={this.props.botsecret}
                  // showWiki={this.props.showWiki}
                  // wikisite={this.props.wikisite}
                  // wikilist={this.props.wikilist}
                  // wikieditlist={this.props.wikieditlist}
                  // wikiiscombo={this.props.wikiiscombo}
                  // dividetable={this.props.dividetable}
                  // tit_context={this.props.tit_context}
                  // tit_details={this.props.tit_details}
                  tit_diagram={this.tit_diagram}
                  tit_hyperlinks={this.tit_hyperlinks}
                  // tit_propWithGroup={this.props.tit_propWithGroup}
                  tit_property={this.tit_property}
                  tit_search={this.tit_search}
                  tit_share={this.tit_share}
                  tit_visiodata={this.tit_visiodata}
                 
                  strings={strings_de()}
                // showNavigator={false}
                />
              </StackItem>
            }
          </Stack>
          {!this.state.showContext &&
            <div onContextMenu={async (e: any) => { await this.onShowContextMenu(e); }}>
              {this.state.isLoading.length > 0 &&
                <Spinner size={SpinnerSize.large} />
              }
              <div id='some-empty-div-on-the-page' className={styles.svgobject} />
            </div>
          }
          {/* {this.state.showContext &&
            <div style={divHostStyle}>
              <Suspense fallback={suspfallback}>
                <SemTalkContext
                  semservice={this.props.semservice}
                  goodlist={[]}
                  badlist={[]}
                  gotonodes={this.props.gotonodes}
                  width={1000} height={1000}
                  root="Antragsteller"
                  controls={true}
                  navigateproperties={true}
                  // graphClient={this.props.graphClient}
                  usegraph={this.props.usegraph}
                  listTitle={"Roles"}
                  strings={this.strings}
                />
              </Suspense>
            </div>
          } */}
          {this.state.showSearch &&
            <Panel
              isOpen={this.state.showSearch}
              type={PanelType.smallFixedFar}
              isBlocking={false}
              onDismiss={() => this._onClose({ showSearch: false })}
              headerText={this.tit_search}
              closeButtonAriaLabel={this.res.getResStr("STRDLGCMDCL")}
              onRenderFooterContent={this.onRenderFooterContent
              }
            >
              <Suspense fallback={suspfallback}>
                <SemTalkSearch
                  semservice={this.props.semservice}
                  searchpage={false}
                  strings={this.strings}
                  res={this.res} />
              </Suspense>
            </Panel>
          }
          {this.state.showProcTable &&
            <Panel
              isOpen={this.state.showProcTable}
              type={PanelType.medium}
              onDismiss={() => this._onClose({ showProcTable: false })}
              isBlocking={false}
              headerText={this.strings.Process}
              closeButtonAriaLabel={this.res.getResStr("STRDLGCMDCL")}
              onRenderFooterContent={this.onRenderFooterContent}
            >
              <Suspense fallback={suspfallback}>
                <SemTalkActivityTable
                  semservice={this.props.semservice}
                  strings={this.strings} />
              </Suspense>
            </Panel>
          }
          {this.state.showDiag &&
            <Panel
              isOpen={this.state.showDiag}
              type={PanelType.smallFixedFar}
              onDismiss={() => this._onClose({ showDiag: false })}
              isBlocking={false}
              headerText={this.tit_diagram}
              closeButtonAriaLabel={this.res.getResStr("STRDLGCMDCL")}
              onRenderFooterContent={this.onRenderFooterContent}
            >
              <Suspense fallback={suspfallback}>
                <SemTalkDiagramTable
                  semservice={this.props.semservice}
                  // goodlist={["Comment", "Kommentar", "Refinement", "Verfeinerung", "Go Up", "Aufwärts"]}
                  // badlist={[]}
                  // navigateproperties={true} 
                  strings={this.strings}
                // gotonodes={true} 
                />
              </Suspense>
            </Panel>
          }
          {this.state.showLinks &&
            <Panel
              isOpen={this.state.showLinks}
              type={this.props.paneltype}
              onDismiss={() => this._onClose({ showLinks: false })}
              isBlocking={false}
              headerText={this.tit_share}
              closeButtonAriaLabel={this.res.getResStr("STRDLGCMDCL")}
              onRenderFooterContent={this.onRenderFooterContent}
            >
              <Suspense fallback={suspfallback}>
                <SemTalkLinks
                  semservice={this.props.semservice}
                  gotonodes={this.props.gotonodes}
                  strings={this.strings} />
              </Suspense>
            </Panel>
          }
          {this.state.showDocumentInfo &&
            <Panel
              isOpen={this.state.showDocumentInfo}
              type={PanelType.smallFixedFar}
              onDismiss={() => this._onClose({ showDocumentInfo: false })}
              headerText={this.tit_visiodata}
              closeButtonAriaLabel={this.res.getResStr("STRDLGCMDCL")}
              onRenderFooterContent={this.onRenderFooterContent}
            >
              <Suspense fallback={suspfallback}>
                <SemTalkDocumentTable
                  semservice={this.props.semservice}
                  strings={this.strings} />
              </Suspense>
            </Panel>
          }
          {this.state.showNav &&
            <Panel
              isOpen={this.state.showNav}
              type={PanelType.smallFixedFar}
              onDismiss={() => this._onClose({ showNav: false })}
              headerText={this.tit_navigation}
              isBlocking={false}
              closeButtonAriaLabel={this.res.getResStr("STRDLGCMDCL")}
              onRenderFooterContent={this.onRenderFooterContent}
            >
              <Suspense fallback={suspfallback}>
                <SemTalkNavigation
                  semservice={this.props.semservice}
                  root={this.props.navroot}
                  // islist={this.props.islist}
                  strings={this.strings}
                // goodlist={this.props.goodlist} badlist={this.props.badlist} 
                />
              </Suspense>
            </Panel>
          }
          {this.state.showProp && !this.props.usedialogs &&
            <Panel
              isOpen={!this.state.hidePropertyDialog}
              type={PanelType.smallFixedFar}
              onDismiss={() => this._onClose({ hidePropertyDialog: true })}
              headerText={this.tit_property}
              isBlocking={false}
              closeButtonAriaLabel={this.res.getResStr("STRDLGCMDCL")}
              onRenderFooterContent={this.onRenderFooterContent}
            >
              <Suspense fallback={suspfallback}>
                <SemTalkPropertyTable
                  semservice={this.props.semservice}
                  res={this.res}
                  // goodlist={this.props.goodlist}
                  // badlist={this.props.badlist}
                  // graphClient={this.props.graphClient}
                  // navigateproperties={true}
                  hidebpmn={this.props.hidebpmn}
                // strings={this.strings}
                />
              </Suspense>
            </Panel>
          }
          {this.state.showCon &&
            <Panel
              isOpen={this.state.showCon}
              type={this.props.paneltype}
              customWidth={contextwidth}
              isBlocking={false}
              onDismiss={() => this._onClose({ showCon: false })}
              headerText={this.tit_context}
              closeButtonAriaLabel={this.res.getResStr("STRDLGCMDCL")}
              onRenderFooterContent={this.onRenderFooterContent}
            >
              <Suspense fallback={suspfallback}>
                <SemTalkContext
                  semservice={this.props.semservice}
                  strings={this.strings}
                  root="Antragsteller"
                  width={800} height={800}
                  // goodlist={this.props.goodlist}
                  // badlist={this.props.badlist}
                  navigateproperties={true}
                  // graphClient={this.props.graphClient}
                  controls={true}
                  gotonodes={this.props.gotonodes} />
              </Suspense>
            </Panel>
          }
          {this.state.showPropG &&
            <Panel
              isOpen={this.state.showPropG}
              type={this.props.paneltype}
              onDismiss={() => this._onClose({ showPropG: false })}
              headerText={this.tit_propWithGroup}
              isBlocking={false}
              closeButtonAriaLabel={this.res.getResStr("STRDLGCMDCL")}
              onRenderFooterContent={this.onRenderFooterContent}
            >
              {/* <SemTalkPropertyGroupedTable context={this.props.context} filter={this.props.filter} token={this.props.token} service={this.props.service}
              goodlist={this.props.goodlist} badlist={this.props.badlist} views={this.props.views} /> */}
            </Panel>
          }
          {this.state.showHie &&
            <Panel
              isOpen={this.state.showHie}
              type={PanelType.smallFixedFar}
              onDismiss={() => this._onClose({ showHie: false })}
              isBlocking={false}
              headerText={this.res.getResStr("STROBJECTS")}
              closeButtonAriaLabel={this.res.getResStr("STRDLGCMDCL")}
              onRenderFooterContent={this.onRenderFooterContent}
            >
              <Suspense fallback={suspfallback}>
                <SemTalkObjects
                  semservice={this.props.semservice}
                  root={this.props.objroot}
                  islist={this.props.objIsList} iscombo={this.props.objIscombo}
                  goodlist={this.props.objGoodlist} badlist={this.props.objBadlist}
                  strings={this.strings} />
              </Suspense>
            </Panel>
          }
          {/* {this.state.showRelTree &&
            <Panel
              isOpen={this.state.showRelTree}
              type={this.props.paneltype}
              onDismiss={() => this._onClose({ showRelTree: false })}
              headerText={this.strings.RelationTree}
              closeButtonAriaLabel={this.res.getResStr("STRDLGCMDCL")}
              onRenderFooterContent={this._onRenderFooterContent}
            >
              <SemTalkRelationTree context={this.props.context} root={this.props.relationroot} filter={this.props.filter} token={this.props.token} service={this.props.service}
              relationlist={this.props.relationlist} goodlist={this.props.objGoodlist} badlist={this.props.objBadlist} />
            </Panel>
          } */}
          {/* {this.state.showWiki &&
            <Panel
              isOpen={this.state.showWiki}
              type={this.props.paneltype}
              onDismiss={() => this._onClose({ showWiki: false })}
              headerText={this.strings.WikiLabel}
              closeButtonAriaLabel={this.res.getResStr("STRDLGCMDCL")}
              onRenderFooterContent={this._onRenderFooterContent}
            >
              <SemTalkWiki context={this.props.context}
              filter={this.props.filter} token={this.props.token} service={this.props.service}
              wikisite={this.props.wikisite}
              wikilist={this.props.wikilist}
              wikieditlist={this.props.wikieditlist}
              gotoObject={true}
              gotoPage={false}
              gotoNodes={false}
              // gotoShapes={false}
              iscombo={this.props.wikiiscombo}
              navigateproperties={true}
              dividetable={this.props.dividetable}
            />
            </Panel>
          } */}
          {this.state.showRep &&
            <Panel
              isOpen={this.state.showRep}
              type={this.props.paneltype}
              isBlocking={false}
              onDismiss={() => this._onClose({ showRep: false })}
              headerText={this.res.getResStr("STRREPORT")}
              closeButtonAriaLabel={this.res.getResStr("STRDLGCMDCL")}
              onRenderFooterContent={this.onRenderFooterContent}
            >
              <SemTalkReport
                semservice={this.props.semservice}
                strings={this.strings}
                pagesize={15} />
            </Panel>
          }
          {/* {this.state.showTea && this.props.graphClient &&
            <Panel
              isOpen={this.state.showTea}
              type={this.props.paneltype}
              onDismiss={() => this._onClose({ showTea: false })}
              headerText={this.strings.Planner}
              closeButtonAriaLabel={this.res.getResStr("STRDLGCMDCL")}
              onRenderFooterContent={this._onRenderFooterContent}
            >
              <SemTalkTeam context={this.props.context} filter={this.props.filter}
              token={this.props.token} service={this.props.service}
              site={this.props.listitemssite}
              portal={this.props.portal}
              graphClient={this.props.graphClient}
              searchmytasks={this.props.searchmytasks}
              teamid={this.props.teamid}
              planid={this.props.planid}
            />
            </Panel>
          } */}
          {/* {this.state.showRol && this.props.graphClient &&
            <Panel
              isOpen={this.state.showRol}
              type={this.props.paneltype}
              onDismiss={() => this._onClose({ showRol: false })}
              headerText={this.strings.RoleAssignment}
              closeButtonAriaLabel={this.res.getResStr("STRDLGCMDCL")}
              onRenderFooterContent={this._onRenderFooterContent}
            >
              <SemTalkTeamMember context={this.props.context} filter={this.props.filter} token={this.props.token} service={this.props.service}
              site={this.props.msite}
              graphClient={this.props.graphClient}
              listTitle={this.props.mTitle}
              teamid={this.props.teamid}
              addnew={true}
              editList={true}

            />
            </Panel>
          } */}
          {/* {this.state.showBot &&
            <Panel
              isOpen={this.state.showBot}
              type={this.props.paneltype}
              onDismiss={() => this._onClose({ showBot: false })}
              headerText={this.strings.Bot}
              closeButtonAriaLabel={this.res.getResStr("STRDLGCMDCL")}
              onRenderFooterContent={this._onRenderFooterContent}
            >
              <SemTalkBot context={this.props.context} filter={this.props.filter} token={this.props.token} service={this.props.service}
              directLineSecret={this.props.botsecret}
            />
            </Panel>
          } */}
          {/* {this.state.showListItems &&
            <Panel
              isOpen={this.state.showListItems}
              type={this.props.paneltype}
              onDismiss={() => this._onClose({ showListItems: false })}
              headerText={this.props.listTitle}
              closeButtonAriaLabel={this.res.getResStr("STRDLGCMDCL")}
              onRenderFooterContent={this._onRenderFooterContent}
            >
              <SemTalkList context={this.props.context}
              filter={this.props.filter} token={this.props.token} service={this.props.service} gotonodes={this.props.gotonodes}
              site={this.props.listitemssite}
              listTitle={this.props.listTitle}
              listFilter={this.props.listFilter}
              columns={this.props.columns}
              query={this.props.listitemsquery}
              editList={this.props.editList}

            />
            </Panel>
          } */}
          {/* {this.state.showListDocuments &&
            <Panel
              isOpen={this.state.showListDocuments}
              type={this.props.paneltype}
              onDismiss={() => this._onClose({ showListDocuments: false })}
              headerText={this.props.dTitle}
              closeButtonAriaLabel={this.res.getResStr("STRDLGCMDCL")}
              onRenderFooterContent={this._onRenderFooterContent}
            >
              <SemTalkListDocuments context={this.props.context}
              filter={this.props.filter} token={this.props.token}
              service={this.props.service} gotonodes={this.props.gotonodes}
              site={this.props.dsite}
              listTitle={this.props.dTitle}
              columns={this.props.dcolumns}
              graphClient={this.props.graphClient}
              //usegraph={this.props.usegraph}
              query={this.props.dquery}
              listFilter={this.props.listFilter}
              editDocumentList={this.props.editDocumentList}
            // filtermodel="Model"
            // filterpage="Page"
            // filterobject="Object"
            // filtershape="ShapeID"
            />
            </Panel>
          } */}
          {/* {this.state.showUse && this.props.graphClient &&
            <Panel
              isOpen={this.state.showUse}
              type={this.props.paneltype}
              onDismiss={() => this._onClose({ showUse: false })}
              headerText={this.strings.UsedDocuments}
              closeButtonAriaLabel={this.res.getResStr("STRDLGCMDCL")}
              onRenderFooterContent={this._onRenderFooterContent}
            >
              <SemTalkUsed title="" context={this.props.context}
              filter={this.props.filter} token={this.props.token} service={this.props.service}
              site={this.props.listitemssite}
              nrOfItems={this.props.nrOfItems} displayMode={displayMode}
              graphClient={this.props.graphClient}
              teamid={this.props.teamid}
              updateProperty={updateProperty} />
            </Panel>
          } */}
          {/* {this.state.showRec && this.props.graphClient &&
            <Panel
              isOpen={this.state.showRec}
              type={this.props.paneltype}
              onDismiss={() => this._onClose({ showRec: false })}
              headerText={this.props.strings.RecentDocuments}
              closeButtonAriaLabel={this.props.res.getResStr("STRDLGCMDCL")}
              onRenderFooterContent={this._onRenderFooterContent}
            >
              <SemTalkRecent title="" context={this.props.context}
              nrOfItems={this.props.nrOfItems} displayMode={displayMode}
              graphClient={this.props.graphClient}
              updateProperty={updateProperty} />
            </Panel>
          } */}
          {/* {this.state.showTre && this.props.graphClient &&
            <Panel
              isOpen={this.state.showTre}
              type={this.props.paneltype}
              onDismiss={() => this._onClose({ showTre: false })}
              headerText={this.props.strings.TrendingDocuments}
              closeButtonAriaLabel={this.props.res.getResStr("STRDLGCMDCL")}
              onRenderFooterContent={this._onRenderFooterContent}
            >
              <SemTalkTrending title="" context={this.props.context}
              filter={this.props.filter} token={this.props.token} service={this.props.service}
              site={this.props.listitemssite}
              nrOfItems={this.props.nrOfItems} displayMode={displayMode}
              graphClient={this.props.graphClient}
              teamid={this.props.teamid}
              updateProperty={updateProperty} />
            </Panel>
          } */}
          {/* {this.state.showWho && this.props.graphClient &&
            <Panel
              isOpen={this.state.showWho}
              type={this.props.paneltype}
              onDismiss={() => this._onClose({ showWho: false })}
              headerText={this.props.strings.People}
              closeButtonAriaLabel={this.props.res.getResStr("STRDLGCMDCL")}
              onRenderFooterContent={this._onRenderFooterContent}
            >
              <SemTalkWhoKnows title="" webpartcontext={this.props.context}
              filter={this.props.filter} token={this.props.token} service={this.props.service}
              site={this.props.listitemssite}
              nrOfItems={this.props.nrOfItems} displayMode={displayMode}
              graphClient={this.props.graphClient}
              defaulttopic={this.props.defaulttopic}
              teamid={this.props.teamid}
              updateProperty={updateProperty} />
            </Panel>
          } */}
          {this.state.showListItems &&
            <Panel
              isOpen={this.state.showListItems}
              type={PanelType.medium}
              isBlocking={false}
              onDismiss={() => {
                this._onClose({ showListItems: false });
              }}
              headerText={this.strings.Issues + ": " + repo}
              closeButtonAriaLabel={this.res.getResStrListener("STRDLGCMDCL")}
            >
              <Suspense fallback={suspfallback}>
                <SemTalkListItems
                  callback={this}
                  mongo={this.props.mongo}
                  context={this.props.semservice.context}
                  res={this.res}
                  usegraph={this.props.usegraph}
                  graphClient={this.props.graphClient}
                  azureAD={this.props.azureAD}
                  site={this.props.sharepointrepository}
                  list={"Issues"}
                />
              </Suspense>
            </Panel>
          }
          {this.state.showSettings &&
            <Panel
              isOpen={this.state.showSettings}
              type={this.props.paneltype}
              onDismiss={() => {
                this._onClose({ showSettings: false });
                window.location.reload();
              }}
              headerText={""}
              closeButtonAriaLabel={this.res.getResStr("STRDLGCMDCL")}
              onRenderFooterContent={this.onRenderSettingsFooterContent}
            >
              <Suspense fallback={suspfallback}>
                <SemTalkSettings
                  title={this.res.getResStr("STROPTIONS")}
                  // semtalk={this.state.semtalk}
                  // guilanguage={}
                  islocked={false}
                  isadmin={true}
                  context={this.props.semservice.context}
                  res={this.res}
                  strings={this.strings}
                  mongo={this.props.mongo}
                />
              </Suspense>
            </Panel>
          }
          {this.state.shownavigator && this.props.usedialogs &&
            <DialogEx isOpen={!this.state.hideNavigatorDialog}
              onDismiss={() => { this._onClose({ hideNavigatorDialog: true }); }}
              dialogContentProps={{ type: DialogType.close, title: "Navigator" }}
              isBlocking={false}
              minWidth={100}
              responsiveMode={ResponsiveMode.medium}
              modalProps={{
                isBlocking: false,
                dragOptions: {
                  moveMenuItemText: 'Move',
                  closeMenuItemText: 'Close',
                  menu: ContextualMenu,
                  keepInBounds: true,
                }, isModeless: true, styles: { main: { width: 100, minHeight: 100 } }
              }}
            >
              <Suspense fallback={suspfallback}>
                <SemTalkNavigator callback={this} res={this.res} />
              </Suspense>
            </DialogEx>
          }
        </div>

        {this.state.showProp && this.props.usedialogs &&
          <DialogEx
            // hidden={!this.state.showProp}
            isOpen={!this.state.hidePropertyDialog}
            isBlocking={false}
            onDismiss={() => this._onClose({ hidePropertyDialog: true })}
            minWidth={400}
            dialogContentProps={{
              type: DialogType.close,
              title: this.res.getResStrListener("STRDLHTMLPROPS"),
            }}
            modalProps={{
              isBlocking: false,
              onDismissed: () => this._onClose({ hidePropertyDialog: true })
            }}
          >
            <SemTalkPropertyTable semservice={this.props.semservice}
              res={this.res}
            // navigateproperties={true} 
            // strings={this.strings}
            // goodlist={this.props.goodlist} badlist={this.props.badlist} 
            />
            <DialogFooterEx>
              <DefaultButton onClick={() => this._onClose({ hidePropertyDialog: true })}
                text={this.res.getResStr("STRDLGCMDCL")} />
            </DialogFooterEx>
          </DialogEx>
        }

        {/* <DialogEx
          hidden={this.state.hideDiagramDialog}
          onDismiss={this._closeDiagramDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: tit,
           }}
          modalProps={{
            isBlocking: false,
          }}
        >
          <SemTalkDiagramTable context={this.props.context}
            filter={this.props.filter} token={this.props.token} service={this.props.service}
            goodlist={["Comment", "Kommentar", "Refinement", "Verfeinerung", "Go Up", "Aufwärts"]} badlist={this.props.badlist}
            navigateproperties={true}
          />
          <DialogFooterEx>
             <DefaultButton onClick={this._closeDiagramDialog} text={strings.Close} />
          </DialogFooterEx>
        </DialogEx> */}
        <ContextualMenu
          items={this.menuItems}
          hidden={!this.state.showContextualMenu}
          target={this.popupMenuRef}
          onItemClick={this.onHideContextualMenu}
          onDismiss={this.onHideContextualMenu}
        />

      </Fabric >
    );
  }
  // private _showPropertyDialog = (): void => {
  //   this.setState({ hidePropertyDialog: false });
  // }

  // private _closePropertyDialog = (): void => {
  //   this.setState({ hidePropertyDialog: true });
  // }
  private _showDiagramDialog = (): void => {
    this.setState({ hideDiagramDialog: false });
  }

  // private _closeDiagramDialog = (): void => {
  //   this.setState({ hideDiagramDialog: true });
  // }
  private decodelzutf8(s: any): any {
    let ss = "";
    try {
      // var b = Buffer.from(s, 'base64');
      ss = lzutf8.decompress(s, { inputEncoding: "Base64" });
    } catch {
      try {
        ss = utf8.decode(s);
      } catch {
        ss = "";
      }
    }
    return ss;
  }
  private loadsvg_from_modelattr(svgpages: IModelAttributeTable[], tagname: string, sh: string) {
    let cnt = document.getElementById(tagname);
    if (cnt !== null) {
      for (let m of svgpages) {
        let svg1: string = m.AttributeValue;
        let s2: string = "";
        try { s2 = this.decodelzutf8(svg1); }
        catch (e) {
          // s2 = wtf8.decode(decoded);
        }
        // try { s2 = utf8.decode(svg1); }
        // catch (e) {
        //   // s2 = wtf8.decode(decoded);
        // }
        // let decoded = base64.decode(svg1);
        try {
          //   let s2: string = "";
          //   try { s2 = utf8.decode(decoded); }
          //   catch (e) {
          //     // s2 = wtf8.decode(decoded);
          //   }
          if (cnt !== null) {
            if (s2 !== null) {
              cnt.innerHTML = s2;
            } else {
              let s3 = atob(svg1);
              if (s3) {
                cnt.innerHTML = s3;
              } else return;
            }
          }
          // this.svgContainer = Snap("#" + tagname);
          // cnt.attributes["height"].value = this.props.height + "px";
          this.svgContainer = Snap(cnt);
          let svg = this.svgContainer.node.firstElementChild;
          this.svgPaper = Snap(svg);
          // cnt.children[0]["width"].baseVal.value=1704;
          // cnt.children[0]["height"].baseVal.value=1704;
          this.initSVG2(this.svgPaper.paper, sh);
          (cnt.children[0].attributes as any)["width"].value = this.props.width + "px";
          (cnt.children[0].attributes as any)["height"].value = this.props.height + "px";
          // svg.firstElementChild.setAttribute('transform','translate(50,50)');
        } catch (e) {
          alert((e as any).message);
          return;
        }
        //      console.log("loadsvg_from_modelattr done: ", pname);
      }
    }
  }
  public componentDidMount = async () => {
    
    // if (this.props.semservice.context) {
    SetContext(this.props.semservice);
    // }
    setStay(this.props.isSingleDocument);
    // this._site = this.props.site;
    // setSite(this.props.semservice.site);
    // console.log("componentDidMount");
    let diagid: number = parseInt(accessCookie(SemTalkCookie.contextpage));
    // let txt = await axiostest();
    // console.log(txt);
    // console.log("diagid:" + diagid);
    // console.log("baseURI ", document.baseURI);

    if (diagid !== null && diagid > -1 && document.baseURI.indexOf("diagid=") < 0) {
      await this.loadlastState(diagid);
    } else {
      if (!this.props.semservice.document || !this.props.semservice.startpage) {
        return;
      }
      await this.loadFromUrl();
    }
  }
  private async loadlastState(diagid: number) {
    let shapeid = accessCookie(SemTalkCookie.contextshp);
    let objid = parseInt(accessCookie(SemTalkCookie.contextobj));
    // console.log("shapeid:" + shapeid);
    // console.log("objid:" + objid);
    // if (this.props.semservice.context) {
    //   SetContext(this.props.semservice);
    // }
    addCallBack(this);
    // this.mounted = true;
    // await DoArgs();

    this.setState({ isLoading: "Loading.." });
    if (shapeid !== "") {
      // gotodefault = false;
      let nd = await FindNodeByShape(diagid, shapeid);
      //console.log("node:" + node);
      if (nd) {
        await gotoNode(nd.ID, nd.Object, nd.NodePage, nd.NodeShape);
        if (objid > -1) {
          await gotoObject(objid);
        }
        // return;
      } else {
        //console.log("Startpage: ", diagid);
        await gotoDocument(diagid);
        // gotodefault = false;
        if (objid > -1) {
          await gotoObject(objid);
        }
        // return;
      }
    } else {
      console.log("Startpage: ", diagid);
      await gotoDocument(diagid);
    }
    this.setState({ isLoading: "" });
  }
  private async loadFromUrl() {
    let mname = this.props.semservice.document;
    if (this.props.semservice.servertype !== "mongo") {

      let sites = await AllSites();

      if (sites.length === 0) {
        alert("Empty database!!");
        return;
      }
      let sitename = sites[0].SiteName;
      if (this.props.semservice.document.indexOf(sitename) === -1)
        mname = sitename + this.props.semservice.document;
    }


    // let m = await FindModelByName(mname);
    // let lastmodel = getModel();
    // if (lastmodel > -1) {
    //   m = await FindModelByID(lastmodel);
    //   if (m) {
    //     if (m.ModelName) mname = m.ModelName;
    //     if (m.ObjectName) mname = m.ObjectName;
    //   }
    // }

    // let mname = this._site + doc.replace("/VSDX/", "/XML/").replace(".vsdx", ".xml");
    // mname= "https://semtalkonline.semtalk.com/semtalkonline/DenkmalschutzrechtlicheGenehmigung.xml";
    // let mname = this.props.semservice.site + "/" + this.props.semservice.database + "/" + this.props.semservice.document;
    this.selectedModel = mname;
    console.log("+" + mname);
    // mname= "https://semtalkonline.semtalk.com/semtalkonline/DenkmalschutzrechtlicheGenehmigung.xml";
    // let docurl = this.props.semservice.documentUrl;
    // let pname = docurl.substr(docurl.indexOf(" --- ") + 5);
    // pname = pname.replace(".svg", "");
    let pname = this.props.semservice.startpage;

    // let d: IDiagramTable | null = null;
    // let lastpage = getDiagram();
    // if (lastpage > -1) {
    //   d = await FindDiagramByID(lastpage);
    //   if (d) {
    //     if (d.ObjectName) pname = d.ObjectName;
    //   }
    // }
    this.selectedPage = pname;

    // this.svgurl = this.props.semservice.documentUrl;

    this.setState({ isLoading: "Loading.." });
    addCallBack(this);
    let m = await FindModelByName(mname);
    if (m) {
      let mid: number = m.ID;
      setModel(mid);
      mname = m.ObjectName;
      mname = mname.replace(".xml", "");
      mname = mname.replace("/VSDX/", "/XML/");
      // if (d === null) d = await FindDiagramByNameID(mname + "#" + pname, mid);
      let d = await FindDiagramByNameID(mname + "#" + pname, mid);
      if (d) {
        setDiagram(d.ID);
        console.log("Startpage: ", d.ObjectCaption);
        // if (this.props.semservice.context) {
        //   SetContext(this.props.semservice);
        // }
        // this.mounted = true;
        let mat = await ModelDiagramSVG(mid, d.ObjectCaption);
        if (mat === null) {
          mat = await ModelDiagramSVG(mid, pname);
        }
        if (mat !== null) {
          let svgpages: IModelAttributeTable[] = [];
          svgpages.push(mat);
          this.loadsvg_from_modelattr(svgpages, "some-empty-div-on-the-page", "");
          // this.setState({ svgpages: svgpages });
        }
        await this._onPageLoadComplete(pname);
        // this.setState({ modelid: mid });
      }
    }
    await DoArgs();
    this.setState({ isLoading: "" });
  }

  private initSVG2 = (paper: any, sh: string) => {
    // console.log("initSVG1");
    // let me: SemTalkSvg = this;
    this.rootPaper = paper;
    if (false) this.resizeSvgAndContainer(null, null);
    this.groupcontext = "v:groupcontext";
    this.selectionRect = this.rootPaper.rect(0, 0, 0, 0);

    // this.setState({
    //   title: this.rootPaper.select("title").node.textContent
    //   });
    this.rootPaper.selectAll("foreignObject").forEach((elm: any, _i: any) => {
      if (elm.node.attributes["pointer-events"]) {
        elm.node.attributes["pointer-events"].value = "none";
      }
    });

    let shapes = this.rootPaper.selectAll("*");

    shapes.forEach((elm: any, _i: any) => {
      // Browsers will pick up the <title> tag and display as a tooltip. This can interfere with your own application, so we can remove it this way.
      // But the title tells us what kind of Visio shape it is, so we keep a copy of it in an atribute instead.
      if (elm.select("title") && elm.select("title").node) {
        let type = elm.select("title").node.textContent;
        elm.attr("shapeType", type);
        elm.select("title").remove();
      }
    });
    // this.rootPaper.selectAll("g[id^=shape]").forEach((elm, _i) => {
    shapes.forEach((elm: any, _i: any) => {
      // Click event
      elm.click(async (evt: any) => {

        // Call a helper function (see further down) to get the Visio shape clicked
        let shape = this.parseShapeElement(elm);
        if (!shape) {
          // if (this.selectionRect) {
          //   this.selectionRect.attr({
          //     x: 0,
          //     y: 0,
          //     width: 0,
          //     height: 0,
          //     fill: "none",
          //     stroke: "red",
          //     strokeWidth: 0
          //   });
          //   // this.selectedShape = "";
          // }
          return;
        }
        this.selectionRect.attr({
          x: shape.x,
          y: shape.y,
          width: shape.width,
          height: shape.height,
          fill: "none",
          stroke: "red",
          strokeWidth: 9
        });

        // Setting "pointerEvents" to none means that the mouse will never be able to click/hover this element.
        this.selectionRect.attr({
          pointerEvents: "none"
        });

        // Setting the "shapeRendering" attribute allows to hint to the browser how shapes should rendered.
        // In this example, "geometricPrecision" appears to give best result. Also try "optimizeSpeed" and "crispEdges"
        this.selectionRect.attr({
          shapeRendering: "geometricPrecision"
        });
        // this.selectedShape = shape.shapeid;
        // this.setState({
        //   selectedShape: shape.shapeid,
        // });

        // Finally stop the click event from bubbling up to the Visio "page"
        evt.preventDefault();
        evt.stopPropagation();
        await this._onSelectionChanged(shape, evt);
      });
      elm.touchstart(async (evt: any) => {

        // Call a helper function (see further down) to get the Visio shape clicked
        let shape = this.parseShapeElement(elm);
        if (!shape) return;
        this.selectionRect.attr({
          x: shape.x,
          y: shape.y,
          width: shape.width,
          height: shape.height,
          fill: "none",
          stroke: "red",
          strokeWidth: 9
        });

        // Setting "pointerEvents" to none means that the mouse will never be able to click/hover this element.
        this.selectionRect.attr({
          pointerEvents: "none"
        });

        // Setting the "shapeRendering" attribute allows to hint to the browser how shapes should rendered.
        // In this example, "geometricPrecision" appears to give best result. Also try "optimizeSpeed" and "crispEdges"
        this.selectionRect.attr({
          shapeRendering: "geometricPrecision"
        });
        // this.selectedShape = shape.shapeid;
        //  this.setState({
        //     selectedShape: shape.shapeid,
        //   });
        await this._onSelectionChanged(shape, evt);

        // Finally stop the click event from bubbling up to the Visio "page"
        evt.preventDefault();
        evt.stopPropagation();
      });
      elm.dblclick(async (evt: any) => {
        let shape = this.parseShapeElement(elm);
        if (!shape) return;
        // this.selectedShape = shape.shapeid;
        // this.setState({
        //   selectedShape: shape.shapeid,
        // });
        let md: any = {};
        md.type = "shapeSelectionChanged";
        md.modelname = this.selectedModel;
        md.pagename = this.selectedPage;
        md.shapeid = shape.shapeid;  //args.shapeNames[0];
        md.refine = false;
        md.prop = true;
        md.hyperlink = false;
        // STProperty('propresults_mod');
        // $('#propertyModal').modal();
        evt.preventDefault();
        evt.stopPropagation();
        this.setState({ hidePropertyDialog: false });
        await getHostPageInfoListener(JSON.stringify(md));
      });
      elm.mouseover((_evt: any) => {
        let shape = this.parseShapeElement(elm);
        if (!shape) return;

        // Set cursor to pointer to indicate that we can click on shapes
        // (We could have set this elsewhere; this was just a convenient place)
        elm.attr({
          cursor: "pointer"
        });

        // When hovering a shape we want some kind of indication.
        // Setting the shape opacity to 50% works fine in our example. Depending on the background underneath the shape this may not work though.
        // Alternatively we could have used a something similar to when selecting shapes, drawing a rectangle around it.
        // Ideally we would want to clone the shape and display on top. Unfortunately I have not gottent this to work properly. The problem likely stems from Visio shapes being complex elements put together of multiple parts.
        if (this.hoveredShape) {
          // First reset any previous shape that we hovered
          this.hoveredShape.attr({
            fillOpacity: "1",
            strokeOpacity: "1"
          });
        }
        // Set the opacity attributes to 50% on the current hovered shape
        this.hoveredShape = elm;
        elm["attr"]({
          fillOpacity: "0.5",
          strokeOpacity: "0.5"
        });

        // Print data found in the Visio shape to the output panel
        // To see how these were obtained, see parseShapeElement() below
        // if (shape) {
        //   me.setState({
        //     output: shape.text + "(" + shape.id + ")",
        //   });
        // } else {
        //   me.setState({
        //     output: "-",
        //   });
        // }
      });
      elm.mouseout((_evt: any) => {
        // Reset a few things when the mouse is no longer hovering the shape
        //  $("#output").empty();
        // me.setState({
        //   output: "-",
        // });
        if (this.hoveredShape) {
          this.hoveredShape.attr({
            fillOpacity: "1",
            strokeOpacity: "1"
          });
          this.hoveredShape = null;
        }
      });
      // let e: any = { "name": "contextmenu", "f": (_e: any) => { 
      //   alert("boo") }};
      // elm.events.push(e);
      // console.debug(elm);
    });
    // Also add a click event handler to the background
    this.rootPaper.click((_evt: any) => {
      // console.log("SVG Click on Paper");
      if (this.selectionRect) {
        this.selectionRect.attr({
          x: 0,
          y: 0,
          width: 0,
          height: 0,
          fill: "none",
          stroke: "red",
          strokeWidth: 0
        });
        // this.selectedShape = "";
      }
      this._onSelectionChanged(null, _evt);
    });
    this.rootPaper.dblclick((evt: any) => {
      // stdiagram.STDiagram('diagramresults_mod');
      // $('#diagramModal').modal();
      if (this._showDiagramDialog) this._showDiagramDialog();
      evt.preventDefault();
      evt.stopPropagation();
    });
    // console.log("initSVG2");
    // this.resizeSvgAndContainer(this.svgContainer, this.rootPaper);
    // if (oldsvg !== null) (oldsvg as any).remove();
    // this.selectedShape = sh;
    // this.setState({ selectedShape: sh });
    //  console.log("initSVG3");
    if (sh !== "") {
      this.gotoShape(sh);
    }
    // if (false) {
    this.panZoom = svgPanZoom(this.svgPaper.node, {
      zoomEnabled: true,
      dblClickZoomEnabled: false,
      // fit: true,
      controlIconsEnabled: false
    });
    // }
    // this.panZoom.zoom(0.5);
    // this.panZoom.center();
  }
  public async componentDidUpdate(_prevProps: ISemTalkSvgProps) {
    setStay(this.props.isSingleDocument);
  }
  public componentWillUnmount() {
    removeCallBack(this);
    // this.mounted = false;
  }

  private parsemxGraphShape(node: any): any {
    let shape: any = {};
    // shape["type"] = elm.attributes["shapeType"].value;
    shape["objectid"] = node.attributes["data-objectid"].value;
    shape["shapeid"] = node.attributes["data-shapeid"].value;
    let x = parseInt(node.attributes["geometry-x"].value);
    if (node.attributes["geometry-sl-x"]) {
      x += parseInt(node.attributes["geometry-sl-x"].value);
    }
    shape["x"] = x;
    let y = parseInt(node.attributes["geometry-y"].value);
    // let y = parseInt(node.attributes["y"].value);
    if (node.attributes["geometry-sl-y"]) {
      y += parseInt(node.attributes["geometry-sl-y"].value);
    }
    shape["y"] = y;
    shape["width"] = node.attributes["geometry-width"].value;
    shape["height"] = node.attributes["geometry-height"].value;
    return shape;
  }
  // This helper function will take an element and try to parse it as a Visio shape
  // It will return a new object with the properties we are interested in
  private parseShapeElement(elm: any): any {
    // Figuring out where things are located in Visio SVG:s and what they are named, such as "v:groupContext" was done by inspecting examples in and ordinary text editor
    let elementType = null;
    if (elm.node.attributes[this.groupcontext]) {
      elementType = elm.node.attributes[this.groupcontext].value;
    } else {
      if (elm.node.attributes["data-objectid"]) {
        let shape: any = this.parsemxGraphShape(elm.node);
        shape["x"] = elm.getBBox().x;
        shape["y"] = elm.getBBox().y;
        if (shape["width"] === '0') shape["width"] = 30;
        if (shape["height"] === '0') shape["height"] = 30;
        return shape;
      } else {
        // for (let elm0 of elm.node.childNodes) {
        //   if (elm0.attributes["data-objectid"]) {
        //     let shape: any = this.parsemxGraphShape(elm0);
        //     return shape;
        //   }
        // }
      }

    }
    if ((elementType === "shape" || elementType === "group") && elm.node !== null) {
      // Create the object to hold all data we collect
      let shape: any = {};

      // The shape type tells us what kind of Visio shape we are dealing with
      shape["type"] = elm.node.attributes["shapeType"].value;

      let binteresting: boolean = false;

      // Let begin collecting data!
      shape["paper"] = elm.paper;

      // Each shape has a unique id
      shape["id"] = elm.node.attributes["id"].value;

      if (elm.node.attributes["v:mid"]) {
        shape["shapeid"] = "Sheet." + elm.node.attributes["v:mid"].value;
      }

      // Shape position is relative to the SVG coordinates, not the screen/browser
      shape["x"] = elm.getBBox().x;
      shape["y"] = elm.getBBox().y;
      shape["width"] = elm.getBBox().width;
      shape["height"] = elm.getBBox().height;

      // Get the text inside the shape
      shape["text"] = "";
      if (elm.select("desc") && elm.select("desc").node) {
        shape["text"] = elm.select("desc").node.textContent;
      }
      shape["props"] = {};
      shape["defs"] = {};
      let pn = elm.node.parentNode;
      if (pn.attributes["xlink:href"]) {
        shape["xlink_href"] = pn.attributes["xlink:href"].value;
      }
      if (pn.attributes["xlink:show"]) {
        shape["xlink_show"] = pn.attributes["xlink:show"].value;
      }
      if (pn.attributes["xlink:title"]) {
        shape["xlink_title"] = pn.attributes["xlink:title"].value;
      }
      if (pn.attributes["target"]) {
        shape["xlink_target"] = pn.attributes["target"].value;
      }

      let cl = elm.node.childNodes;
      for (let j = 0; j < cl.length; j++) {
        let cn = cl[j];
        if (cn.nodeName === "v:custprops") {
          let cpl = cn.childNodes;
          for (let k = 0; k < cpl.length; k++) {
            let un = cpl[k];
            if (un.tagName === "v:cp") {
              if (un.attributes["v:nameu"] && un.attributes["v:val"]) {
                shape["defs"][un.attributes["v:nameu"].value] = un.attributes["v:val"].value;
              }
            }
          }
        }
        if (cn.nodeName === "v:userdefs") {
          let ul = cn.childNodes;
          for (let k = 0; k < ul.length; k++) {
            let un = ul[k];
            if (un.nodeName === "v:ud") {
              if (un.attributes["v:nameu"] && un.attributes["v:val"]) {
                let nameu: string = un.attributes["v:nameu"].value;
                let nameul: string = nameu.toLowerCase();
                if (nameul === "SemTalkInstID".toLowerCase() || nameul === "SemTalkClassID".toLowerCase()) {
                  binteresting = true;
                }
                shape["defs"][nameu] = un.attributes["v:val"].value;
              }
            }
          }
        }
      }
      if (!binteresting) {
        return null;
      }
      return shape;
    } else {
      // Not a Visio shape
      return null;
    }
  }


  private resizeSvgAndContainer(_objectElement: any, _rp: any) {
    let ws: string = this.props.width;
    let hs: string = this.props.height;
    this.rootPaper.attr({ width: ws, height: hs });
    //  console.log("resizeSvgAndContainer2");
    return;
  }

  private isKey(evt: any, key: string): boolean {
    switch (key) {
      case "ctrl":
        return evt.ctrlKey === true;
      case "shift":
        return evt.shiftKey === true;
      case "alt":
        return evt.altKey === true;
      case "":
      case "always":
        return true;
    }
    return false;
  }

  private _onSelectionChanged = async (selectedShape: any, evt: any) => {
    if (selectedShape !== null && this.selectedPage !== "") {
      let md: any = {};
      // if (selectedShape.defs &&selectedShape.defs.SemTalkSubAddress && 
      //   this.isKey(evt, this.props.svgrefine) && 
      //   !this.isKey(evt, this.props.svgprop)) {
      //   let pname: string = selectedShape.defs.SemTalkSubAddress;
      //   pname = pname.substr(4, pname.length - 5);
      //   md.type = "GotoPage";
      //   md.modelname = this.xmlurl;
      //   md.pagename = pname;
      //   md.refine = this.isKey(evt, this.props.svgrefine);
      //   md.prop = this.isKey(evt, this.props.svgprop);
      //   md.hyperlink = this.isKey(evt, this.props.svghyperlink);
      //   await getHostPageInfoListener(JSON.stringify(md));
      //   return;
      // }
      if (selectedShape.defs && selectedShape.defs.SemTalkHyperlink &&
        this.isKey(evt, this.props.svghyperlink) &&
        !this.isKey(evt, this.props.svgprop)) {
        let pname: string = selectedShape.defs.SemTalkHyperlink;
        pname = pname.substr(4, pname.length - 5);
        window.open(pname, "_blank");
        // return;
      }
      md.type = "shapeSelectionChanged";
      md.modelname = this.selectedModel;
      md.pagename = this.selectedPage;
      md.shapeid = selectedShape.shapeid;  //args.shapeNames[0];
      md.refine = this.isKey(evt, this.props.svgrefine);
      md.prop = this.isKey(evt, this.props.svgprop);
      md.hyperlink = this.isKey(evt, this.props.svghyperlink);
      await getHostPageInfoListener(JSON.stringify(md));
    }else{
      let md: any = {};
      md.type = "shapeSelectionRemoved";
      md.modelname = this.selectedModel;
      md.pagename = this.selectedPage;
      md.shapeid = "";  //args.shapeNames[0];
      await getHostPageInfoListener(JSON.stringify(md));
    }
  }
  private _onPageLoadComplete = async (selectedPage: string) => {

    console.log("Page load complete: ", selectedPage);
    this.selectedPage = selectedPage;
    // this.setState({
    //   selectedPage: selectedPage
    // });
    // this.currentPage = selectedPage;
    let md: any = {};
    md.type = "GotoPage";
    md.modelname = this.selectedModel;
    md.pagename = selectedPage;
    await getHostPageInfoListener(JSON.stringify(md));

  }


  public handleEvent = async (m: any): Promise<void> => {
    let mstr = JSON.stringify(m);
    await this.eventListener({ data: mstr });
  }

  private eventListener = async (e: any) => {
    let md: any;
    try {
      //  console.log(e.data);
      md = JSON.parse(e.data);
    }
    catch (error) {
      // console.log("Could not parse the message response.");
      return;
    }
    let mtype = md.type;
    switch (mtype) {
      case "gotoDocument": {
        let pagename = md.pagename;
        let modelname = md.modelname;
        let d = pagename;
        if (d.indexOf("#") > 0) {
          d = d.substring(d.indexOf("#") + 1);
        }
        // d = d.replace("?", "").replace(":", "").replace("/", "");
        // let m = modelname;
        // if (m.indexOf("/XML/") > 0) {
        //   m = m.substring(m.indexOf("/XML/") + 5).replace(".xml", "");
        // }
        // let docurl = this.props.semservice.documentUrl;
        // let svglib = docurl.substr(0, docurl.indexOf(" --- "));
        // svglib = svglib.substring(0, svglib.lastIndexOf("/") + 1);
        // let svg1 = svglib + m + " --- " + d + ".svg";
        this.selectedModel = modelname;
        // if (this.svgurl !== svg1) {
        // this.svgurl = svg1;
        // let mod = await FindModelByID(md.modelid);
        // if (mod) {
        addDocumentToHistory(md.modelname, md.diagid);
        if (this.selectedPage !== d) {
          await this.gotoSVGDocument1(md.modelid, d, "");
          this.selectedPage = d;
        }
        // };
        // }
        // this.setState({ modelid: md.modelid, selectedPage: d });
      }
        break;
      case "gotoObject":
        break;
      case "gotoNode": {
        let txt = "";
        let val = "";
        await this.gotoNode(md.modelid, md.modelname, md.pagename, txt, md.shapeid, val);
      }
        break;
      case "gotoShape": {
        let txt = "";
        let val = "";
        await this.gotoNode(md.modelid, md.modelname, md.pagename, txt, md.shapeid, val);
      }
        break;
      case "showPivot": {
        setCookie(SemTalkPortalCookie.pivotvisible, md.value);
        this.setState({ showpivot: md.value });
        break;
      }
      case "showContext": {
        // this.setState({ showContext: md.value });
        break;
      }
    }
    // if (md.message === "getHostPageInfo") {
    // }
    // if (md.message === "getHostSemTalkHomePage") {
    // }
  }
  private gotoNode = async (modelid: number, md: string, pg: string, _txt: string, sh: string, _val: string) => {
    // try {
    let d = pg;
    if (d.indexOf("#") > 0) {
      d = d.substring(d.indexOf("#") + 1);
    }
    // let m = md;
    // if (m.indexOf("/XML/") > 0) {
    //   m = m.substring(m.indexOf("/XML/") + 5).replace(".xml", "");
    // }
    // let docurl = this.props.semservice.documentUrl;
    // let svglib = docurl.substr(0, docurl.indexOf(" --- "));
    // svglib = svglib.substring(0, svglib.lastIndexOf("/") + 1);
    // let svg1 = svglib + m + " --- " + d + ".svg";

    this.selectedModel = md;
    if (getModel() !== modelid) {
      setModel(modelid);
    }

    // if (this.svgurl !== svg1) {
    if (this.selectedPage !== d) {
      await this.gotoSVGDocument1(modelid, d, sh);
      // this.svgurl = svg1;
      this.selectedPage = d;
    }
    this.gotoShape(sh);


    // let mod = await FindModelByID(modelid);
    // if (mod) {
    //   if (this.svgurl !== svg1 || sh !== this.selectedShape) {
    //     if (sh !== this.selectedShape && this.svgurl === svg1) {
    //       this.gotoShape(sh);
    //     } else {
    //       this.svgurl = svg1;
    //       await this.gotoSVGDocument1(this._site, mod.ID, d, sh);
    //     }
    //   }
    // }
    // this.setState({ modelid: modelid, selectedPage: d });
    // }
    // catch (e) {
    //   alert(pg + ': ' + txt);
    // }
  }
  private setselection(shape: any) {
    if (!shape) return;
    let color = "blue";
    let shpid = getShape();
    if (shape.shapeid === shpid) {
      color = "red";
    }
    this.selectionRect.attr({
      x: shape.x,
      y: shape.y,
      width: shape.width,
      height: shape.height,
      fill: "none",
      stroke: color,
      strokeWidth: 9
    });

  }
  private gotoShape = (sh: string) => {
    if (sh === "" && this.selectionRect) {
      this.selectionRect.attr({
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        fill: "none",
        stroke: "red",
        strokeWidth: 0
      });
    } else {
      if (this.selectionRect) {
        if (this.rootPaper) {
          let fnd = false;
          this.rootPaper.selectAll("g").forEach((elm: any, _i: any) => {
            if (!fnd && elm.node && elm.node.attributes["v:mid"] && "Sheet." + elm.node.attributes["v:mid"].value === sh) {
              let shape = this.parseShapeElement(elm);
              if (shape) {
                this.setselection(shape);
                fnd = true;
              }
            }
          });    // this.props.visioService.selectShapeByID(sh);
          this.rootPaper.selectAll("*").forEach((elm: any, _i: any) => {
            if (!fnd && elm.node && elm.node.attributes["data-shapeid"] && elm.node.attributes["data-shapeid"].value === sh) {
              let shape = this.parseShapeElement(elm);
              this.setselection(shape);
              fnd = true;
            }
          });    // this.props.visioService.selectShapeByID(sh);
          // this.rootPaper.selectAll("ellipse").forEach((elm: any, _i: any) => {
          //   if (!fnd && elm.node && elm.node.attributes["data-shapeid"] && elm.node.attributes["data-shapeid"].value === sh) {
          //     let shape = this.parseShapeElement(elm);
          //     this.setselection(shape);
          //     fnd = true;
          //   }
          // });    // this.props.visioService.selectShapeByID(sh);
        }
        // Setting "pointerEvents" to none means that the mouse will never be able to click/hover this element.
        this.selectionRect.attr({
          pointerEvents: "none"
        });

        // Setting the "shapeRendering" attribute allows to hint to the browser how shapes should rendered.
        // In this example, "geometricPrecision" appears to give best result. Also try "optimizeSpeed" and "crispEdges"
        this.selectionRect.attr({
          shapeRendering: "geometricPrecision"
        });
      }
      return;
    }
  }


  private async gotoSVGDocument1(mid: number, d: string, sh: string) {
    // this._site = site;
    if (this.selectedPage !== d) {
      let mat = await ModelDiagramSVG(mid, d);
      if (mat) {
        let svgpages: IModelAttributeTable[] = [];
        svgpages.push(mat);
        this.loadsvg_from_modelattr(svgpages, "some-empty-div-on-the-page", sh);
        this.setState({ svgpages: svgpages });
      }
    }
  }
  public showHelp = (page?: string): void => {
    console.log(page);
    }
  public DoCommand(cmd: SemTalkOnlineCommand, _args: any): boolean {
    switch (cmd) {
      case SemTalkOnlineCommand.ShowOptions: { this.showOptions(); break; }
      case SemTalkOnlineCommand.ZoomIn: { this.zoomIn(); break; }
      case SemTalkOnlineCommand.ZoomOut: { this.zoomOut(); break; }
      case SemTalkOnlineCommand.ZoomFit: { this.zoomFit(); break; }
      case SemTalkOnlineCommand.ZoomActual: { this.zoomActual(); break; }
      case SemTalkOnlineCommand.GoBack: { this.goBackForward(-1); break; }
      case SemTalkOnlineCommand.GoForward: { this.goBackForward(1); break; }
    }
    return true;
  }
  public showOptions = (): void => {
    this.setState({
      showSettings: true
    });
  }
  private zoomIn = () => { if (this.panZoom) this.panZoom.zoomIn(); };
  private zoomOut = () => { if (this.panZoom) this.panZoom.zoomOut(); };
  private zoomFit = () => { if (this.panZoom) this.panZoom.zoomFit(); };
  private zoomActual = () => { if (this.panZoom) this.panZoom.resetZoom(); };

  public isBackward = (): boolean => {
    return isBackward();
  }
  public isForward = (): boolean => {
    return isForward();
  }

  private goBackForward = (s: number) => {
    let f = accessCookie(SemTalkCookie.history);
    if (f) {
      let entries: IHistory = JSON.parse(f);
      let len: number = 1;
      if (accessCookie(SemTalkCookie.historypos) === null) {
        len = Object.keys(entries.history).length + s;
      } else {
        len = Number(accessCookie(SemTalkCookie.historypos)) + s;
      }
      let entry: IHistoryEntry = entries.history[len];
      if (entry) {
        setCookie(SemTalkCookie.historypos, String(len));
        if (entry.filename === entries.history[len - s].filename) {
          gotoDocument(entry.id);
        } else {
          if (entry.filename !== "") {
            gotoDocument(entry.id);
            // this.loadDocument(entry.filename);
          }
        }
      }
    }
  }
}
